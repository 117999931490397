import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pdf } from "../../components/icons";
import moment from "moment";
import { get, orderBy } from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.scss";
import HomeSidebar from "../HomeSidebar/container";
import ReactModal from "react-modal";
import themeUpdateImg from "../../images/lion-tree-weekly-update.png"
import IcClose from "../../images/ic-close.svg";
import { set } from "immutable";
const HOST_URL = process.env.REACT_APP_API_HOSTNAME;
const IMAGE_BLOB_URL = process.env.REACT_APP_IMAGE_BLOB_URL;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function FeedComponent(props) {
  const [weeklyData, setWeeklyData] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  // const [totalRecords, setTotalRecords] = useState(0);
  const [isPosition, setIsPostionSet] = useState(false);
  const [isAnalyticSave, setIsAnalyticSave] = useState(false);
  const [topLtWeeklyThemes, setTopLtWeeklyThemes] = useState([]);
  const [isloadMore, setIsLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState([]);
  const [mailModalIsOpen,setMailModalIsOpen] =useState(false)
  const [successModalIsOpen,setSuccessModalIsOpen] =useState(false)
const [themeLink,setThemeLink]= useState("")
const [isEmailExists,setIsEmailExists]=useState(localStorage.getItem("t")?true:false)
const [userEmail,setUserEmail]= useState("")
const [errorMessage,setErrorMessage]=useState("")
const [emailloader,setEmailLoader]=useState(false)
const [isAcknwoledgmentModalOpen,setIsAcknwoledgmentModalOpen] = useState(false)
  const navigate = useNavigate();

  const scrollPosition = (s) => {
    window.scrollTo(0, s);
  };

  // const loadMore = async () => {
  //   let email = localStorage.getItem("email");
  //   let format = "MMMM DD, YYYY";
  //   let currentUtcTime = new Date();
  //   // Converts the UTC time to a locale specific format, including adjusting for timezone.
  //   let currentDateTimeCentralTimeZone = new Date(
  //     currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  //   );
  //   let dayNight =
  //     currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
  //   let currentDate = moment(new Date(), format).format(format);
  //   let currentHr = currentDateTimeCentralTimeZone.getHours();
  //   let currentMin = currentDateTimeCentralTimeZone.getMinutes();
  //   currentDate =
  //     currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
  //   let obj = {
  //     Date: currentDate,
  //     Url: window.location.href.replace("https://", "").replace("http://", ""),
  //     ActionType: "clicked",
  //     NewsletterID: "",
  //     NewsletterDate: "",
  //     ThemeID: "",
  //     ActionDescription: "Load More Click",
  //     ArticleTitle:"Load More Click"
  //     // Device: getDevice(),
  //     // Browser: browserName,
  //   };
  //   props.SavedLTWebsiteAnalytics(obj);
  //   setIsLoading(true);
  //   let newNumber = get(props, "totalPageNumber", 0) + 1;
  //   await props.getLtweeklyData({
  //     pageNumber: newNumber,
  //     limit: limit,
  //   });
  //   setPageNumber(newNumber);
  // };

  const handleScroll = () => {
    const position = window.pageYOffset;
    props.updateCurrentPosition({ currentPosition: position });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pageViewLink = (actionDescription) => {
    // let itemDate = window.location.pathname.split("/")[2];
    // let itemId = window.location.pathname.split("/")[3];
    // itemId = itemId ? itemId : "";
    // itemDate =
    //   itemDate &&
    //   itemDate !== "archives" &&
    //   itemDate !== "about" &&
    //   itemDate !== undefined
    //     ? moment(itemDate, "MM DD YYYY").format("MMMM DD, YYYY")
    //     : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "pageview",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
      ArticleTitle:actionDescription
      // Device: getDevice(),
      // Browser: browserName,
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const handledClicked = (actionDescription, val) => {
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
      ArticleTitle:actionDescription
      // Device: getDevice(),
      // Browser: browserName,
    };
    props.SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const getLongDescription = (str, data) => {
    str = str.substr(0, 500);
    if (str.includes("<a")) {
      return str
        .substr(0, 380)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false"> read more</a>`
        )
        .replace("", "");
    } else {
      return str
        .substr(0, 210)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false"> read more</a>`
        )
        .replace("", "");
    }
  };


  const linkClicked = (actionDescription, url, mainId,itemName="") => {
    let articleTitle = "";
    actionDescription = getThemeTitle(actionDescription);
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "This Week's Other Curated News" &&
      actionDescription !== "Read more" &&
      actionDescription !== "Intro"
    ) {
      articleTitle = actionDescription;
      actionDescription = "Theme Title - " + actionDescription;
    }
    let itemDate = url.split("/")[2];
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "This Week's Other Curated News" &&
      actionDescription !== "Read more" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "clicked",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      ArticleTitle: articleTitle?articleTitle:actionDescription,
      ThemIndex:itemName
      // Device: getDevice(),
      // Browser: browserName,
    };
    props.SavedLTWebsiteAnalytics(obj);
    
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      if (!isAnalyticSave) {
        setIsAnalyticSave(true);
        pageViewLink("Home Page");
      }
      navigate("/new-home");
      setLimit(10);
      const fetchData = async () => {
        await props.getLtweeklyData({
          pageNumber: props.totalPageNumber,
          limit: limit,
        });
      };
      const fetchTopThemes = async () => {
        const { value } = await props.GetTopLtWeeklyTheme({
          page: pageNumber,
          count: 18,
    });
        if (value?.Success) setTopLtWeeklyThemes(get(value, "Result", []));
        setTotalCount(get(value, "TotalCount", 0))
      };

      fetchTopThemes();
      if (props.totalLtweeklyData.length > 0) {
        setWeeklyData(props.totalLtweeklyData);
      } else {
        fetchData();
        setIsMounted(true);
      }
    }
    if (!isPosition) {
      let timeOff = setInterval(() => {
        scrollPosition(get(props, "currentPosition", 0));
        clearInterval(timeOff);
      }, 400);
      setIsPostionSet(true);
    }
    if (get(props, "ltweeklyPhase", "") === "success") {
      props.initHomePhase();
      if (props.totalLtweeklyData.length > 0) {
        setWeeklyData(props.totalLtweeklyData);
      } else {
        const concateData = [...weeklyData, ...get(props, "ltweeklyData", [])];
        setWeeklyData(concateData);
      }
      // setIsLoading(false);
      // setTotalRecords(get(props, "ltweeklyTotalRecords", ""));
    }
  }, [weeklyData, isMounted, limit, props]);

  const getThemeTitle = (title) => {
    if (title?.includes("<span")) {
      title = title?.split("<span>")[1];
      title = title?.split("</span>")[0];
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title?.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;

          if (title?.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title?.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title?.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title?.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const contentClickHandler = (data) => {
    navigate(
      `/LTWeekly/${moment(get(data, "ItemName", ""), "MM DD YYYY").format(
        "MMMM-DD-YYYY"
      )}/${get(data, "ItemID", "")}`
      // { state: { data: data, isFrom: "feed" } }
    );
    // window.location.reload(true);
  };
  const redirectToLink = (link) => {
    const updatedlink =link.replaceAll("?t=","")
    window.open(updatedlink, "_blank");
  };
  const getHrefUrl = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const linkElement = doc.querySelector("a");

    // Fetch the href value
    const hrefValue = linkElement?.getAttribute("href");
    return hrefValue;
  };
  const loadMore = async () => {
    setIsLoadMore(true);
    setIsLoading(true)
    let page = pageNumber + 1;

    const { value } = await props.GetTopLtWeeklyTheme({
      page: page,
      count: 18,
    });
    if (value?.Success){
      setIsLoading(false);
      setTopLtWeeklyThemes([...topLtWeeklyThemes,...get(value, "Result", [])])
      setTotalCount(get(value, "TotalCount", 0));
      setPageNumber(page);
      setIsLoadMore(false);
    }
  };
  const closeMailModal = () => {
   setMailModalIsOpen(false)
   setErrorMessage("")
   setThemeLink("")
   setUserEmail("")
  };
  const openMailModal = (getLink) => {
    setThemeLink(getLink)
    setMailModalIsOpen(true)
  };
  const closeSuccessModal = () => {
   setSuccessModalIsOpen(false)
   setUserEmail("")
  };
  const openSuccessModal = () => {
    setSuccessModalIsOpen(true)
  };
  const checkUser= async (email,link)=>{
    if(email === ""){
      setErrorMessage('Please enter email')
      return
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      setErrorMessage('Invalid Email Address')
      return
    }
    let obj ={
      email:email,
      link:link?link:"",
    }
    setEmailLoader(true)
    const {value}=await props.CheckUserAuthenticity(obj)

    if (value?.Success){
      setEmailLoader(false)
      if(value.Message==="User is present, Send Magic Link!!"){
      setIsEmailExists(localStorage.getItem("t")?true:false)
      openSuccessModal()
      }
      else{
        opneAcknwoledgmentModal()
      }
    }else {
      setEmailLoader(false)
    window.location.href = 'https://lionrb1dev.wpengine.com/iimform?inboundpath=themeweb'
      setUserEmail("")
      setMailModalIsOpen(false)
    }
    setMailModalIsOpen(false)
    setErrorMessage("")
  }
  const handleChangeEmail =(email) =>{
    const userEmail=email
    setUserEmail(userEmail)
    setErrorMessage("")
  }
  const opneAcknwoledgmentModal =()=>{
    setIsAcknwoledgmentModalOpen(true)
  }
  const closeAcknowledgmentModal=()=>{
    setIsAcknwoledgmentModalOpen(false)
  }
  
  return (
    <>
      {!(weeklyData.length > 0) ? (
        <>
          <h1 style={{ marginTop: 200 }}> </h1>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
            className="loader__full"
          />
        </>
      ) : (
        <div className="main__wrapper">
          {/* <div className="d-flex flex-wrap justify-content-between align-items-start"> */}
          <div className="">
            <div className="feed__container border-none main__wrapper--left">
              <div>
                <div className="feed__row border-global">
                  {weeklyData.length > 0 &&
                    weeklyData.map((data, i) => {
                      data.ChildList = orderBy(
                        data.ChildList,
                        (item) => parseInt(item.ItemName),
                        ["asc"]
                      );
                      if (i === 0) {
                        return (
                          <div key={i} className="feed__item">
                            <div className="feed__container__heading">
                              <h2>
                                What We View As Most Important The Week Ending….
                              </h2>
                            </div>
                            <div className="feed__header">
                              <h1 className="feed__title">
                                {moment(
                                  get(data, "ItemName", ""),
                                  "MM DD YYYY"
                                ).format("MMM D, YYYY")}
                              </h1>
                              {/* 
                         `${HOST_URL}/api/utility/pdf?path=%2fsitecore%2fcontent%2fKindredApp3%2fLT+Weekly%2f${data.ItemName}`,
                      */}
                              <a
                                onClick={(e) =>{
                                  e.preventDefault();
                                  if (!localStorage.getItem("t")){
                                    openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                      get(data, "ItemName", ""),
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      data,
                                      "ItemID",
                                      ""
                                    )}`)
                                  }else{
                                  handledClicked(
                                    "Download Pdf",
                                    `${HOST_URL}/Dashboard/pdf?WeeklyDate=${data.ItemName}`
                                  )
                                }}}
                                // href="javascript:void(0)"
                                href="#"
                                className="feed__download"
                                // target="_blank"
                                rel="noreferrer"
                              >
                                <Pdf />
                              </a>
                            </div>
                            <Link
                              onClick={() => {
                                if(!localStorage.getItem("t")){
                                  openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                    get(data, "ItemName", ""),
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    data,
                                    "ItemID",
                                    ""
                                  )}`)
                                }else{
                                linkClicked(
                                  "Intro",
                                  `/LTWeekly/${moment(
                                    get(data, "ItemName", ""),
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    data,
                                    "ItemID",
                                    ""
                                  )}`,
                                  get(data, "ItemID", "")
                                );
                                navigate(
                                  `/LTWeekly/${moment(
                                    get(data, "ItemName", ""),
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    data,
                                    "ItemID",
                                    ""
                                  )}`
                                  // { state: { data: data, from: "feed" } }
                                );
                                window.location.reload(true);
                              }}}
                              to={`#`}
                              // state={{ data, isFrom: "feed" }}
                              className="feed__thumb"
                            >
                              <img
                                src={
                                  get(data, "Photo", "")?.includes(
                                    IMAGE_BLOB_URL
                                  )
                                    ? get(data, "Photo", "")?.replaceAll(
                                        HOST_URL,
                                        ""
                                      )
                                    : get(data, "Photo", "")
                                }
                                alt=""
                              />
                            </Link>
                            <div className="feed__desc">
                              <div className="read-more-content">
                                <div
                                  onClick={() => {
                                    if(!localStorage.getItem("t")){
                                      openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        data,
                                        "ItemID",
                                        ""
                                      )}`)
                                    }else{
                                    linkClicked(
                                      "Read more",
                                      `/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        data,
                                        "ItemID",
                                        ""
                                      )}`,
                                      get(data, "ItemID", "")
                                    );
                                    
                                    contentClickHandler(data);
                                  }}}
                                  className="feed__editor"
                                  dangerouslySetInnerHTML={{
                                    __html: getLongDescription(
                                      data.LongDescription,
                                      data
                                    ),
                                  }}
                                ></div>
                              </div>
                              <ul className="feed__desc--links">
                                <li key={get(data, "ItemID", "") + 0}>
                                  {/* <Link
                                  onClick={() => {
                                    linkClicked(
                                      "Intro",
                                      `/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        data,
                                        "ItemId",
                                        ""
                                      )}`,
                                      get(data, "ItemId", "")
                                    );
                                    navigate(
                                      `/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        data,
                                        "ItemId",
                                        ""
                                      )}`
                                      // { state: { data: data, from: "feed" } }
                                    );
                                    window.location.reload(true);
                                  }}
                                  to={`#`}
                                  // state={{ data, isFrom: "feed" }}
                                >
                                  Intro
                                </Link> */}
                                  <ol className="feed__desc--links-child">
                                    {get(data, "ChildList", []).length > 0 &&
                                      get(data, "ChildList", []).map(
                                        (item, j) => {
                                          return (
                                            <li key={j}>
                                              <Link
                                                onClick={() => {
                                                if(!localStorage.getItem("t")){
                                                  openMailModal(
                                                    `${
                                                      process.env.REACT_APP_SITE_URL
                                                    }/LTWeekly/${moment(
                                                      get(data, "ItemName", ""),
                                                      "MM DD YYYY"
                                                    ).format(
                                                      "MMMM-DD-YYYY"
                                                    )}/${get(
                                                      item,
                                                      "ItemID",
                                                      ""
                                                    )}`)
                                                  }else{
                                                  linkClicked(
                                                    get(
                                                      item,
                                                      "ShortDescription",
                                                      ""
                                                    ),
                                                    `/LTWeekly/${moment(
                                                      get(data, "ItemName", ""),
                                                      "MM DD YYYY"
                                                    ).format(
                                                      "MMMM-DD-YYYY"
                                                    )}/${get(
                                                      item,
                                                      "ItemID",
                                                      ""
                                                    )}`,
                                                    get(data, "ItemID", ""),
                                                    get(item, "ItemName","")
                                                  );
                                                  navigate(`/LTWeekly/${moment(
                                                       get(data, "ItemName", ""),
                                                       "MM DD YYYY"
                                                     ).format("MMMM-DD-YYYY")}/${get(
                                                      item,
                                                      "ItemID",
                                                     ""
                                                    )}`)
                                                    window.location.reload(true)
                                                }}}
                                                // to={`/LTWeekly/${moment(
                                                //   get(data, "ItemName", ""),
                                                //   "MM DD YYYY"
                                                // ).format("MMMM-DD-YYYY")}/${get(
                                                //   item,
                                                //   "ItemID",
                                                //   ""
                                                // )}`}
                                                to={`#`}
                                                 state={{ data, isFrom: "feed" }}
                                              
                                              >
                                                {getThemeTitle(
                                                  get(
                                                    item,
                                                    "ShortDescription",
                                                    ""
                                                  )
                                                )}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                  </ol>
                                </li>
                                {get(data, "Stock", "") !== "" &&
                                  get(data, "Stock", "") !== null && (
                                    <li key={get(data, "ItemID", "") + 1}>
                                      <Link
                                        onClick={() =>{
                                          if(!localStorage.getItem("t")){
                                            openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                              get(data, "ItemName", ""),
                                              "MM DD YYYY"
                                            ).format("MMMM-DD-YYYY")}/${get(
                                              data,
                                              "ItemID",
                                              ""
                                            )}/stock-check`)
                                          }else{
                                          linkClicked(
                                            "Stock Market Check",
                                            `/LTWeekly/${moment(
                                              get(data, "ItemName", ""),
                                              "MM DD YYYY"
                                            ).format("MMMM-DD-YYYY")}/${get(
                                              data,
                                              "ItemID",
                                              ""
                                            )}/stock-check`,
                                            get(data, "ItemID", "")
                                          )                                   
                                        navigate (`/LTWeekly/${moment(
                                          get(data, "ItemName", ""),
                                          "MM DD YYYY"
                                        ).format("MMMM-DD-YYYY")}/${get(
                                          data,
                                          "ItemID",
                                          ""
                                        )}/stock-check`)
                                        window.location.reload(true)
                                      }}}
                                        to={'#'}
                                        // state={{ data, isFrom: "feed" }}
                                      >
                                        Stock Market Check
                                      </Link>
                                    </li>
                                  )}
                                {get(data, "OtherNews", []).length > 0 && (
                                  <li key={get(data, "ItemID", "") + 2}>
                                    <Link
                                      onClick={() =>{
                                        if(!localStorage.getItem("t")){
                                          openMailModal(`${process.env.REACT_APP_SITE_URL}
                                            /LTWeekly/${moment(
                                            get(data, "ItemName", ""),
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            data,
                                            "ItemID",
                                            ""
                                          )}/sub-sector`)
                                        }
                                     else{
                                        linkClicked(
                                          "This Week's Other Curated News",
                                          `/LTWeekly/${moment(
                                            get(data, "ItemName", ""),
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            data,
                                            "ItemID",
                                            ""
                                          )}/sub-sector`,
                                          get(data, "ItemID", "")
                                        )
                                      navigate(`/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        data,
                                        "ItemID",
                                        ""
                                      )}/sub-sector`)
                                      window.location.reload(true)
                                    }}}
                                      to={'#'}
                                      // state={{ data, isFrom: "feed" }}
                                    >
                                      This Week's Other Curated News
                                    </Link>
                                  </li>
                                )}
                              </ul>
                              <div className="text-end">
                                <button
                                  className="archives-btn"
                                  onClick={() => {
                                    navigate(`/LTWeekly/archives/2024`);
                                  }}
                                  // to={`/LTWeekly/archives/2024`}
                                  // state={{ data, isFrom: "feed" }}
                                >
                                  Prior Editions
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                </div>

                <div className="top-themes">
                  <h3>Most Viewed Recent Themes</h3>
                  <div className="news-grid border-global">
                    {topLtWeeklyThemes.length > 0 &&
                      topLtWeeklyThemes.map((data, i) => {
                        return (
                          <div className="news-grid-card">
                            <div
                              onClick={() => {
                                if(!localStorage.getItem("t")){
                                  openMailModal(
                                    getHrefUrl(data.shortdescription)
                                  )
                                }else{
                                redirectToLink(
                                  getHrefUrl(data.shortdescription)
                                );
                              }
                              }}
                              className="news-thumb-grid"
                            >
                              <img
                                style={{
                                  backgroundSize: `cover`,
                                  backgroundPosition: `center`,
                                  border:"5px solid #4D1B6C"
                                }}
                                className="image"
                                src={
                                  data.Photo?.includes("westus2storpublic")
                                    ? data.Photo
                                    : process.env.REACT_APP_API_HOSTNAME +
                                      data.Photo
                                }
                                alt=""
                              />
                              <div className="theme-update">
                                      <img src={themeUpdateImg}/>
                                    </div>
                            </div>

                            <div className="content-details">
                              {data.category ? (
                                <div className="news--block mb-0 border-bottom-0">
                                  {" "}
                                  {data.category && data.category !== "" && (
                                    <p className="purple-font">
                                      {data.category}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="news--block mb-0 border-bottom-0"></div>
                              )}{" "}
                              <p className="news-grid-para">
                                {data.created !== "" &&
                                  data.created !== null &&
                                  moment(data.created, "YYYY-MM-DD").format(
                                    "MMMM DD, YYYY"
                                  )}{" "}
                              </p>
                              <div
                                className="news-grid-heading georgia-font"
                                onClick={() => {
                                  if(!localStorage.getItem("t")){
                                    openMailModal(
                                      getHrefUrl(data.shortdescription)
                                    )
                                  }else{
                                  redirectToLink(
                                    getHrefUrl(data.shortdescription)
                                  );
                                }}}
                              >
                                <a
                                  // href={'#'}
                                  className="news-title"
                                  // target="_blank"
                                  // rel="noopener noreferrer"
                                  style={{ textDecoration: "underline" }}
                                >
                                  {getThemeTitle(data.shortdescription)}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="text-center w-100">
                    { totalCount > topLtWeeklyThemes.length && (
                      <button
                        className="readmorebtn"
                        // to={`/LTWeekly/archives/2024`}
                        onClick={() => loadMore()}
                      >
                         {isloadMore ? (
                          <Spin indicator={antIcon} />
                        ) : ( "See More Top Viewed Themes"
                        )}
                      </button>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HomeSidebar ltweeklyDataNew={weeklyData[0]} />
          </div>
        </div>
        
      )}
                                    <ReactModal
                                      isOpen={mailModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeMailModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      portalClassName="react-modal- email-media-modal"
                                      // overlayClassName="modal"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)"
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                          <h5 className="header-title">Access Theme</h5>
                                          {/* <button
                                            onClick={closeMailModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                    

                                        <div className="bluebg">
                                          <label className="custom-label">Email Address</label>
                                          <input type="text" className="custom-input"
                                          onChange={(e) =>
                                            handleChangeEmail(e.target.value)
                                          } />
                                        </div>
                                        <div style={{ textAlign: "left", color: "red", paddingLeft:"20px"}}>
                                        {errorMessage && (
                                          <>
                                         {errorMessage}
                                         </>
                                        )}
                                        </div>
                                        <div className="text-end">
                                        <button className="bluebtn"
                                         onClick={()=>checkUser(userEmail,themeLink)}>
                                         {emailloader ? (
                                     <Spin indicator={antIcon} />
                                      ) : (
                                      "Request Login Link"
                                       )}
                                        
                                         </button>
                                        </div>
                                        <div className="text-center">
                                          <p className="gated-popoup"><a href="https://lionrb1dev.wpengine.com/iimform?inboundpath=themeweb">Click here</a> if you are not on the list of subscribers.</p>
                                        </div>
                                      </div>
                                    </ReactModal>
                                    <ReactModal
                                      isOpen={successModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeSuccessModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex: "999",
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Check your email</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                      

                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">We sent an email to you at {userEmail}. It has a magic link that will sign you in.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeSuccessModal()}>Close</button>
                                        </div>

                                      </div>
                                    </ReactModal>
                                    <ReactModal
                                      isOpen={isAcknwoledgmentModalOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeAcknowledgmentModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={false}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex: "999",
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                      <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Hi There!</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">A LionTree member will review your request and respond within 24-48 hours.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeAcknowledgmentModal()}>OK</button>
                                        </div>
                                      </div>
                                    </ReactModal>
    </>
  );
}
