import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles.scss";
import { get } from "lodash";
import moment from "moment";
import { Interweave } from "interweave";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import OtherNewsDefault from "../../images/other_news_default.png";
import { set } from "immutable";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function HomeComponent(props) {
  const [keyNewsData, setKeyNewsData] = useState([]);
  const [pollData, setPollData] = useState(null);
  const [pollAnswer, setPollAnswer] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [pollResult, setPollResult] = useState([]);
  const [pollResultOnSubmit, setPollResultOnSubmit] = useState([]);
  const [totalPollResults, setTotalPollResults] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newsDate, setNewsDate] = useState("");
  const [page, setPage] = useState(1);
  const [loader, setIsLoader] = useState(true);
  const [keyNewsDataWithDate, setKeyNewsDataWithDate] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [divPollHeight, setDivPollHeight] = useState(0);
  const [divPollResultHeight, setDivPollResultHeight] = useState(0);
  const [pollDataPresent, setPollDataPresent] = useState(false);
  const [pollResultPresent, setPollResultPresent] = useState(false);
  const [activeTab, setActiveTab] = useState("POLL");
  const [isPollVisible, setIsPollVisible] = useState(true);

  const newsListRef = useRef(null);
  const navigate = useNavigate();
  const pollSectionRef = useRef(null);
  const newsSectionRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      if (!isMounted) {
        const { value } = await props.getLatestLTWeeklyNews({
          page: 1,
          count: 50,
        });
        setKeyNewsData(get(value, "Result.Data", []));
        setPollData(get(value, "Result.latestPoll", null));
        if (get(value, "Result.latestPoll", null)) {
          setPollDataPresent(true);
        } else {
          setPollDataPresent(false);
        }
        setCorrectAnswer(get(value, "Result.latestPoll.Answer", ""));
      }
    };

    const fetchPollResults = async () => {
      if (!isMounted) {
        const { value } = await props.getPollResult({
        });
        setPollResult(get(value, "Result", []));
        if (get(value, "Result", [])) {
          setPollResultPresent(true);
        } else {
          setPollResultPresent(false);
        }
      }
    };
    //
    fetchData();
    fetchPollResults();
    if (!isMounted) {
      // let i = 1;
      // const intervalId = setInterval(() => {
      //   fetchMoreNews(i);
      //   i++;
      //   if (i >= 5) {
      //     clearInterval(intervalId); // Stop the interval after 5 iterations
      //   }
      // }, 1000);
    }
    setIsMounted(true);
  }, [isMounted]);

  const handledClicked = (actionDescription, val, data,shortDescription) => {
    
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    actionDescription = actionDescription + " " + data.ExternalUrlSite;
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
      ArticleTitle:shortDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const getDescription = (a) => {
    let contentString = a;
    if (!a?.includes("<strong>")) {
      let b = a.slice(0, a.indexOf(". "));
      return b;
    } else {
      // let c = a.slice(a.indexOf(". ") + 1, a.length);
      let strippedContent = a
        .replaceAll("<strong>", "")
        .replaceAll("</strong>", ""); // Remove HTML tags

      let periodIndex = strippedContent.indexOf(". ");
      let c = strippedContent.slice(
        0,
        periodIndex !== -1 ? periodIndex + 1 : strippedContent.length
      );

      return c;
    }
    // ;
  };
  const transformText = (url,shortDescription, node, children) => {
    
    if (node.tagName === "a") {
      return (
        <a
          onClick={() => handledClicked("External Link -",url, "",shortDescription)}
          href={url}
          target="_blank"
        >
          {children}
        </a>
      );
    }
  };
  const handlePollAnswer = async () => {
    // setIsSubmitted(true)
    setSuccessMessage("");
    setIsLoading(true);
    if (pollAnswer == "" || pollAnswer == null) {
      setIsLoading(false);
      setSuccessMessage("Please select an option");
      return;
    }
    let obj = {
      PollId: pollData?.Id,
      LtweeklyId: pollData?.LtweeklyId,
      Email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
      Answer: pollAnswer,
    };
    const value = await props.sendPollAnswer(obj);

    if (value?.value?.Success) {
      setPollResultOnSubmit(value?.value?.Result);
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };
  const handleOptionChange = (e) => {
    setPollAnswer("");
    setSuccessMessage("");

    if (e.target.value) {
      setPollAnswer(e.target.value);
    }
  };

  const closingDate = moment(
    get(pollData, "Created", ""),
    "YYYY-MM-DD"
  )
    .add(7, "days")
    .format("MM/DD/YY");
  const previousPollDate =
    pollResult && pollResult.length > 0 ? pollResult[0]?.LtweeklyDate : "";
  const previousWeekDate = moment(previousPollDate, "MM DD YYYY").format(
    "MM/DD/YY"
  );

  useEffect(() => {
    let total = 0;
    const totalCount =
      pollResult && pollResult.length > 0
        ? pollResult?.map((item, index) => {
            total = total + item.count;
          })
        : "";
    setTotalResults(total);
  }, [pollResult]);

  useEffect(() => {
    let total = 0;
    const totalCountNew =
      pollResultOnSubmit && pollResultOnSubmit.length > 0
        ? pollResultOnSubmit?.map((item, index) => {
            total = total + item.count;
          })
        : "";
    setTotalPollResults(total);
  }, [pollResultOnSubmit]);

  const calculatePollPercentage = (count) => {
    return totalPollResults > 0
      ? ((count / totalPollResults) * 100).toFixed(2)
      : "";
  };

  const calculatePercentage = (count) => {
    return totalResults > 0 ? ((count / totalResults) * 100).toFixed(2) : "";
  };
  const filterNewsByDate = () => {
    const newsDataWithDate = [];
    keyNewsData.forEach((newsItem) => {
      const date = newsItem?.Created.split("T")[0];
      let data = {};
      let obji = newsDataWithDate.find((s) => s["ItemName"] == date);
      if (newsDataWithDate.find((s) => s["ItemName"] == date) == undefined) {
        data = { ItemName: date, data: [newsItem] };
        newsDataWithDate.push(data);
      } else {
        let objIndex = newsDataWithDate.find((s) => s["ItemName"] == date);
        objIndex?.data.push(newsItem);
      }
    });

    setKeyNewsDataWithDate(newsDataWithDate);
  };
  useEffect(() => {
    if (keyNewsData.length > 0) {
      filterNewsByDate();
      //   keyNewsDataWithDate.forEach((data, i) => {
      //     data?.data?.map((d,j)=>{
      //     if (d?.PhotoUrl && d?.PhotoUrl !== "") {
      //       imageOk(d?.PhotoUrl.replaceAll("&amp;", "&"), j);
      //     }
      //     })

      //   });
      setInterval(() => {
        setIsLoader(false);
      }, 3000);
    }
    setIsLoader(false);
  }, [keyNewsData]);

  // const handleScroll = () => {
  //   console.log('currentDateRef.current')

  //   const { current } = newsListRef;
  //   const scrollTop = newsListRef.current.scrollTop;
  //   currentDateRef.current.style.transform = `translateY(${scrollTop}px)`;
  //   console.log(currentDateRef.current,'currentDateRef.current')
  //   // Calculate the middle position of the scrollable container
  //   const middlePosition = current.scrollHeight / 2 - current.clientHeight / 2;

  //   // Check if the scroll position reaches the middle position
  //   const isAtMiddle = current.scrollTop >= middlePosition;

  //   if (isAtMiddle && !isLoadingMore && !apiCalledAtMiddle) {
  //     setIsLoadingMore(true);
  //     setApiCalledAtMiddle(true);
  //   }
  // };

  const fetchMoreNews = async (i = 0) => {
    const pageNumber = i + 1;
    const { value } = await props.getLatestLTWeeklyNews({
      page: pageNumber,
      count: 50,
    });
    const newNewsData = get(value, "Result.Result.Data", []);
    setPage(pageNumber);
    // Filter and organize new data by date
    const newDataByDate = {};
    newNewsData.forEach((newsItem) => {
      const date = newsItem?.Created?.split("T")[0];
      if (date) {
        if (!newDataByDate[date]) {
          newDataByDate[date] = [newsItem];
        } else {
          newDataByDate[date].push(newsItem);
        }
      }
    });

    // Update keyNewsDataWithDate based on filtered data
    setKeyNewsDataWithDate((prevData) => {
      const updatedData = [...prevData];
      Object.entries(newDataByDate).forEach(([date, data]) => {
        const existingDateIndex = updatedData.findIndex(
          (item) => item?.ItemName === date
        );

        if (existingDateIndex !== -1) {
          // Date group exists, add new data to existing group
          updatedData[existingDateIndex].data.push(...data);
        } else {
          // Date group doesn't exist, create a new group
          updatedData.push({ ItemName: date, data: data });
        }
      });
      return updatedData;
    });

    setPage(pageNumber);
  };
  const handleMouseEnter = () => {
    setIsPaused(true);
  };
  const handleMouseLeave = () => {
    setIsPaused(false);
  };
  // useEffect(() => {
  //   const outerScroll = document.querySelector(".outer-scroll");
  //   if (outerScroll) {
  //     outerScroll.addEventListener("mouseenter", handleMouseEnter);
  //     outerScroll.addEventListener("mouseleave", handleMouseLeave);

  //     return () => {
  //       outerScroll.removeEventListener("mouseenter", handleMouseEnter);
  //       outerScroll.removeEventListener("mouseleave", handleMouseLeave);
  //     };
  //   }
  // }, []);

  useEffect(() => {
    const divPollResultElement = document.getElementById("poll-result-h"); // Replace 'yourDivId' with the actual ID of your div
    const divPollResultHeight = divPollResultElement?.offsetHeight;
    setDivPollResultHeight(divPollResultHeight);

    const divPollElement = document.getElementById("poll-data"); // Replace 'yourDivId' with the actual ID of your div
    const divPollHeight = divPollElement?.offsetHeight;
    setDivPollHeight(divPollHeight);
    // if (newsListRef.current) {
    //   newsListRef.current.addEventListener("scroll", handleScroll);
    //   // newsListRef.current.addEventListener("scroll", updatePosition);
    // }

    //   return () => {
    //     if (newsListRef.current) {
    //       newsListRef.current.removeEventListener("scroll", handleScroll);
    //       // newsListRef.current.removeEventListener("scroll", updatePosition);
    //     }
    //   };
  }, [keyNewsDataWithDate, pollData, pollResult, pollResultOnSubmit]);
  const currentDateRefs = useRef([]);
  useEffect(() => {}, [isPaused]);

  const funcnew = () => {
    const options = {
      root: null, // Set to null to use the viewport as the root
      threshold: 0.5, // Adjust the threshold as needed
    };
    const dynamicContent = document.querySelector(".outer-scroll");

    if (dynamicContent) {

      var scrollableDiv = dynamicContent;
      const scrollSpeed = 0.1; // Adjust scroll speed as needed

      const scrollAmount = scrollSpeed * (scrollableDiv.scrollHeight / 100);
      if (
        Math.ceil(scrollableDiv.scrollTop + scrollableDiv.clientHeight) >=
        scrollableDiv.scrollHeight
      ) {

        setNewsDate(
          moment(keyNewsDataWithDate[0]?.ItemName, "YYYY-MM-DD").format(
            "MMM DD, YYYY"
          )
        );
        scrollableDiv.scrollTop = 0;
      } else {

        scrollableDiv.scrollTop += scrollAmount; // Adjust scroll speed as needed
      }
      // scrollableDiv.addEventListener("wheel", function (e) {
      //   handleMouseLeave();
      //   var scrollableDiv = document.getElementById(".outer-scroll");
      //   if (scrollableDiv) {
      //     var deltaY = e.deltaY;
      //     scrollableDiv.scrollTop += deltaY;
      //     if (
      //       scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      //       scrollableDiv.scrollHeight
      //     ) {
      //       console.log('eterennnnn1')

      //       scrollableDiv.scrollTop = 0;
      //       setNewsDate(
      //         moment(keyNewsDataWithDate[0]?.ItemName, "YYYY-MM-DD").format(
      //           "MMM DD, YYYY"
      //         )
      //       );
      //     } else if (scrollableDiv.scrollTop <= 0 && deltaY < 0) {
      //       currentDateRefs.current.forEach((ref) => {
      //         observer.observe(ref);
      //       });
      //       console.log('test')
      //       scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      //     }
      //      e.preventDefault(); // Prevent page scrolling
      //   }
      // });
      // scrollableDiv.addEventListener("scroll", function (e) {
      //   handleMouseLeave();
      //   var scrollableDiv = document.getElementById(".outer-scroll");
      //   if (scrollableDiv) {
      //     var deltaY = e.deltaY;
      //     scrollableDiv.scrollTop += deltaY;
      //     console.log(scrollableDiv.scrollTop,"here==>")
      //     if (
      //       scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      //       scrollableDiv.scrollHeight
      //     ) {
      //       // If scrolled to the bottom, reset scrollTop to 0
      //       scrollableDiv.scrollTop = 0;
      //       setNewsDate(
      //         moment(keyNewsDataWithDate[0]?.ItemName, "YYYY-MM-DD").format(
      //           "MMM DD, YYYY"
      //         )
      //       );
      //     } else if (scrollableDiv.scrollTop <= 0 && deltaY < 0) {
      //       currentDateRefs.current.forEach((ref) => {
      //         observer.observe(ref);
      //       });
      //       // If scrolled to the top and trying to scroll up, reset scrollTop to the bottom
      //       scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      //     }
      //     e.preventDefault(); // Prevent page scrolling
      //   }
      // });
    }

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // const dateObject = entry.target.dataset.dateObject;
          // if (dateObject) {
          setNewsDate(
            moment(entry.target.textContent, "YYYY-MM-DD").format(
              "MMM DD, YYYY"
            )
          );
          // }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    currentDateRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  };

  useEffect(() => {
    let intervalId;

    if (!isPaused) {
      intervalId = setInterval(funcnew, 50); // Adjust the interval duration as needed
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clean up the interval when the component unmounts or isPaused changes
  }, [isPaused]);

  useEffect(() => {
    let scrollTimer;

    const handleScroll = () => {
      setIsPaused(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setIsPaused(false);
      }, 2000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimer);
      setIsPaused(false);
    };
  }, []);

  const changeTab = (tab) => {
    if (tab === "POLL") {
      setActiveTab("POLL");
    } else {
      setActiveTab("Results");
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const pollSectionHeight = pollSectionRef.current?.getBoundingClientRect().height || 0;
      
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      
      
      
      if (scrollPosition > pollSectionHeight) {

        setIsPollVisible(false); 
      } 
      else {        
        setIsPollVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
      className={
        (pollData !== null && pollData !== "") ||
        (pollResult !== null && pollResult !== "") ||
        (pollResultOnSubmit !== null && pollResultOnSubmit !== "")
          ? "home__sidebar main__wrapper--right autoscroll-sec"
          : "home__sidebar main__wrapper--right"
      }
    >
      {(pollDataPresent && pollResultPresent && totalResults>0) && (isPollVisible) && (
        <span className="custom-tabs-wrapper">
          <span
            className={`poll-tab ms-4 me-3 ${
              activeTab == "POLL" ? "poll-tab-active" : "poll-tab"
            }`}
            onClick={() => changeTab("POLL")}
          >
            {" "}
            POLL
          </span>
          <span
            className={`poll-tab ${
              activeTab == "Results" ? "poll-tab-active" : "poll-tab"
            }`}
            onClick={() => changeTab("Results")}
          >
            RESULTS
          </span>
        </span>
      )}
      {isPollVisible && (
      <div className="card-scrollbar" ref={pollSectionRef}>
        {isMounted &&
          pollData &&
          pollData !== "" &&
          activeTab == "POLL" &&
          pollDataPresent && pollResultPresent ?(
            <div className="gray-card mb-0 pb-0 poll-data ">
              <div className="box-border">
                <>
                  <h3 className="text-center">Our Weekly Poll</h3>
                  <h6>Poll closes {closingDate}</h6>
                  <p className="mb-3">{pollData?.Question}</p>
                  {!isSubmitted ? (
                    <div className="mb-3">
                      <div className="mb-2">
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          onChange={(e) => handleOptionChange(e)}
                          value={pollData?.Option1}
                        />
                        <label for="test1">{pollData?.Option1}</label>
                      </div>
                      <div className="mb-2">
                        <input
                          type="radio"
                          id="test2"
                          name="radio-group"
                          onChange={(e) => handleOptionChange(e)}
                          value={pollData?.Option2}
                        />
                        <label for="test2">{pollData?.Option2}</label>
                      </div>
                      {pollData?.Option3 && pollData?.Option3 !== "" && (
                        <div className="mb-2">
                          <input
                            type="radio"
                            id="test3"
                            name="radio-group"
                            onChange={(e) => handleOptionChange(e)}
                            value={pollData?.Option3}
                          />
                          <label for="test3">{pollData?.Option3}</label>
                        </div>
                      )}
                      {pollData?.Option4 && pollData?.Option4 !== "" && (
                        <div className="mb-2">
                          <input
                            type="radio"
                            id="test4"
                            name="radio-group"
                            onChange={(e) => handleOptionChange(e)}
                            value={pollData?.Option4}
                          />
                          <label for="test4">{pollData?.Option4}</label>
                        </div>
                      )}
                    </div>
                  ) : (
                    // <div className="mb-3">
                    //   <div className="mb-2">
                    //     <input
                    //       type="radio"
                    //       id="test1"
                    //       name="radio-group"
                    //       onChange={(e) => handleOptionChange(e)}
                    //       value={pollData?.Option1}
                    //     />
                    //     <label
                    //       for="test1"
                    //       className={
                    //         pollAnswer === pollData?.Option1 &&
                    //         pollAnswer !== correctAnswer
                    //           ? "wrong-answer"
                    //           : pollAnswer !== "" &&
                    //             (pollAnswer === correctAnswer ||
                    //               pollAnswer !== correctAnswer) &&
                    //             correctAnswer === pollData?.Option1
                    //           ? "correct-answer"
                    //           : ""
                    //       }
                    //     >
                    //       {pollData?.Option1}
                    //       {pollAnswer !== "" &&
                    //         (pollAnswer === correctAnswer ||
                    //           pollAnswer !== correctAnswer) &&
                    //         correctAnswer === pollData?.Option1 && (
                    //           <span className="correct-answer"> - Correct</span>
                    //         )}
                    //       {pollAnswer !== correctAnswer &&
                    //         pollAnswer === pollData?.Option1 &&
                    //         " - (Your answer is incorrect)"}
                    //     </label>
                    //   </div>
                    //   <div className="mb-2">
                    //     <input
                    //       type="radio"
                    //       id="test2"
                    //       name="radio-group"
                    //       onChange={(e) => handleOptionChange(e)}
                    //       value={pollData?.Option2}
                    //     />
                    //     <label
                    //       for="test2"
                    //       className={
                    //         pollAnswer === pollData?.Option2 &&
                    //         pollAnswer !== correctAnswer
                    //           ? "wrong-answer"
                    //           : pollAnswer !== "" &&
                    //             (pollAnswer === correctAnswer ||
                    //               pollAnswer !== correctAnswer) &&
                    //             correctAnswer === pollData?.Option2
                    //           ? "correct-answer"
                    //           : ""
                    //       }
                    //     >
                    //       {pollData?.Option2}
                    //       {pollAnswer !== "" &&
                    //         (pollAnswer === correctAnswer ||
                    //           pollAnswer !== correctAnswer) &&
                    //         correctAnswer === pollData?.Option2 && (
                    //           <span className="correct-answer"> - Correct</span>
                    //         )}
                    //       {pollAnswer !== correctAnswer &&
                    //         pollAnswer === pollData?.Option2 &&
                    //         " - (Your answer is incorrect)"}
                    //     </label>
                    //   </div>
                    //   {pollData?.Option3 && pollData?.Option3 !== "" && (
                    //     <div className="mb-2">
                    //       <input
                    //         type="radio"
                    //         id="test3"
                    //         name="radio-group"
                    //         onChange={(e) => handleOptionChange(e)}
                    //         value={pollData?.Option3}
                    //       />
                    //       <label
                    //         for="test3"
                    //         className={
                    //           pollAnswer === pollData?.Option3 &&
                    //           pollAnswer !== correctAnswer
                    //             ? "wrong-answer"
                    //             : pollAnswer !== "" &&
                    //               (pollAnswer === correctAnswer ||
                    //                 pollAnswer !== correctAnswer) &&
                    //               correctAnswer === pollData?.Option3
                    //             ? "correct-answer"
                    //             : ""
                    //         }
                    //       >
                    //         {pollData?.Option3}
                    //         {pollAnswer !== "" &&
                    //           (pollAnswer === correctAnswer ||
                    //             pollAnswer !== correctAnswer) &&
                    //           correctAnswer === pollData?.Option3 && (
                    //             <span className="correct-answer">
                    //               {pollData?.Option3} - Correct
                    //             </span>
                    //           )}
                    //         {pollAnswer !== correctAnswer &&
                    //           pollAnswer === pollData?.Option3 &&
                    //           " - (Your answer is incorrect)"}
                    //       </label>
                    //     </div>
                    //   )}
                    //   {pollData?.Option4 && pollData?.Option4 !== "" && (
                    //     <div className="mb-2">
                    //       <input
                    //         type="radio"
                    //         id="test4"
                    //         name="radio-group"
                    //         onChange={(e) => handleOptionChange(e)}
                    //         value={pollData?.Option4}
                    //       />
                    //       <label
                    //         for="test4"
                    //         className={
                    //           pollAnswer === pollData?.Option4 &&
                    //           pollAnswer !== correctAnswer
                    //             ? "wrong-answer"
                    //             : pollAnswer !== "" &&
                    //               (pollAnswer === correctAnswer ||
                    //                 pollAnswer !== correctAnswer) &&
                    //               correctAnswer === pollData?.Option4
                    //             ? "correct-answer"
                    //             : ""
                    //         }
                    //       >
                    //         {pollData?.Option4}
                    //         {pollAnswer !== "" &&
                    //           (pollAnswer === correctAnswer ||
                    //             pollAnswer !== correctAnswer) &&
                    //           correctAnswer === pollData?.Option4 && (
                    //             <span className="correct-answer"> - Correct</span>
                    //           )}
                    //         {pollAnswer !== correctAnswer &&
                    //           pollAnswer === pollData?.Option4 &&
                    //           " - (Your answer is incorrect)"}
                    //       </label>
                    //     </div>
                    //   )}
                    // </div>
                    // ) : (
                    <div>
                      {pollResultOnSubmit && correctAnswer && (
                        <p className="correct-anser-label">
                          Correct Answer -{" "}
                          {(pollResultOnSubmit[0]?. CorrectAnswer&& pollData.Option1) && pollResultOnSubmit[0]?. CorrectAnswer=== "1"?pollData.Option1:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option2) && pollResultOnSubmit[0]?. CorrectAnswer=== "2"?pollData.Option2:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option3) && pollResultOnSubmit[0]?. CorrectAnswer=== "3"?pollData.Option3:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option4) && pollResultOnSubmit[0]?. CorrectAnswer=== "4"?pollData.Option4:""}
                        </p>
                      )}
                      {pollResultOnSubmit &&
                        totalPollResults > 0 &&
                        pollResultOnSubmit
                          ?.sort((a, b) => b.count - a.count)
                          .map((option) => (
                            <>
                              {option?.Answer && (
                                <React.Fragment key={option.Answer}>
                                  <label className="option-label">{`${calculatePollPercentage(
                                    option?.count
                                  )}% - ${option?.Answer}`}</label>
                                  <div className="progressbar-gray">
                                    <span
                                      className="progressbar-purple"
                                      style={{
                                        width: `${calculatePollPercentage(
                                          option?.count
                                        )}%`,
                                      }}
                                    ></span>
                                  </div>
                                </React.Fragment>
                              )}
                            </>
                          ))}
                    </div>
                  )}
                  {!isSubmitted && (
                    <button
                      className="btn btn__purple btn__subscribe w-100 position-relative min-ht-52"
                      isDisabled={pollAnswer === "" ? true : false}
                      onClick={() => {
                        handlePollAnswer();
                      }}
                    >
                      {isLoading ? <Spin indicator={antIcon} /> : "Submit"}
                    </button>
                  )}
                  {/* <div className="divider-line">
                {successMessage && successMessage !== "" && (
                  <p className="text-center mb-2 succes-msg-green">
                    {successMessage}
                  </p>
                )}
              </div> */}
                </>
              </div>
            </div>
          ):("")}
        {isMounted &&
        pollData &&
        pollData !== "" &&
        pollDataPresent &&
        !pollResultPresent ? (
          <div className="gray-card mb-0 pb-0 poll-data ">
            <div className="box-border">
              <>
                <h3 className="text-center">Our Weekly Poll</h3>
                <h6>Poll closes {closingDate}</h6>
                <p className="mb-3">{pollData?.Question}</p>
                {!isSubmitted ? (
                  <div className="mb-3">
                    <div className="mb-2">
                      <input
                        type="radio"
                        id="test1"
                        name="radio-group"
                        onChange={(e) => handleOptionChange(e)}
                        value={pollData?.Option1}
                      />
                      <label for="test1">{pollData?.Option1}</label>
                    </div>
                    <div className="mb-2">
                      <input
                        type="radio"
                        id="test2"
                        name="radio-group"
                        onChange={(e) => handleOptionChange(e)}
                        value={pollData?.Option2}
                      />
                      <label for="test2">{pollData?.Option2}</label>
                    </div>
                    {pollData?.Option3 && pollData?.Option3 !== "" && (
                      <div className="mb-2">
                        <input
                          type="radio"
                          id="test3"
                          name="radio-group"
                          onChange={(e) => handleOptionChange(e)}
                          value={pollData?.Option3}
                        />
                        <label for="test3">{pollData?.Option3}</label>
                      </div>
                    )}
                    {pollData?.Option4 && pollData?.Option4 !== "" && (
                      <div className="mb-2">
                        <input
                          type="radio"
                          id="test4"
                          name="radio-group"
                          onChange={(e) => handleOptionChange(e)}
                          value={pollData?.Option4}
                        />
                        <label for="test4">{pollData?.Option4}</label>
                      </div>
                    )}
                  </div>
                ) : (
                  // <div className="mb-3">
                  //   <div className="mb-2">
                  //     <input
                  //       type="radio"
                  //       id="test1"
                  //       name="radio-group"
                  //       onChange={(e) => handleOptionChange(e)}
                  //       value={pollData?.Option1}
                  //     />
                  //     <label
                  //       for="test1"
                  //       className={
                  //         pollAnswer === pollData?.Option1 &&
                  //         pollAnswer !== correctAnswer
                  //           ? "wrong-answer"
                  //           : pollAnswer !== "" &&
                  //             (pollAnswer === correctAnswer ||
                  //               pollAnswer !== correctAnswer) &&
                  //             correctAnswer === pollData?.Option1
                  //           ? "correct-answer"
                  //           : ""
                  //       }
                  //     >
                  //       {pollData?.Option1}
                  //       {pollAnswer !== "" &&
                  //         (pollAnswer === correctAnswer ||
                  //           pollAnswer !== correctAnswer) &&
                  //         correctAnswer === pollData?.Option1 && (
                  //           <span className="correct-answer"> - Correct</span>
                  //         )}
                  //       {pollAnswer !== correctAnswer &&
                  //         pollAnswer === pollData?.Option1 &&
                  //         " - (Your answer is incorrect)"}
                  //     </label>
                  //   </div>
                  //   <div className="mb-2">
                  //     <input
                  //       type="radio"
                  //       id="test2"
                  //       name="radio-group"
                  //       onChange={(e) => handleOptionChange(e)}
                  //       value={pollData?.Option2}
                  //     />
                  //     <label
                  //       for="test2"
                  //       className={
                  //         pollAnswer === pollData?.Option2 &&
                  //         pollAnswer !== correctAnswer
                  //           ? "wrong-answer"
                  //           : pollAnswer !== "" &&
                  //             (pollAnswer === correctAnswer ||
                  //               pollAnswer !== correctAnswer) &&
                  //             correctAnswer === pollData?.Option2
                  //           ? "correct-answer"
                  //           : ""
                  //       }
                  //     >
                  //       {pollData?.Option2}
                  //       {pollAnswer !== "" &&
                  //         (pollAnswer === correctAnswer ||
                  //           pollAnswer !== correctAnswer) &&
                  //         correctAnswer === pollData?.Option2 && (
                  //           <span className="correct-answer"> - Correct</span>
                  //         )}
                  //       {pollAnswer !== correctAnswer &&
                  //         pollAnswer === pollData?.Option2 &&
                  //         " - (Your answer is incorrect)"}
                  //     </label>
                  //   </div>
                  //   {pollData?.Option3 && pollData?.Option3 !== "" && (
                  //     <div className="mb-2">
                  //       <input
                  //         type="radio"
                  //         id="test3"
                  //         name="radio-group"
                  //         onChange={(e) => handleOptionChange(e)}
                  //         value={pollData?.Option3}
                  //       />
                  //       <label
                  //         for="test3"
                  //         className={
                  //           pollAnswer === pollData?.Option3 &&
                  //           pollAnswer !== correctAnswer
                  //             ? "wrong-answer"
                  //             : pollAnswer !== "" &&
                  //               (pollAnswer === correctAnswer ||
                  //                 pollAnswer !== correctAnswer) &&
                  //               correctAnswer === pollData?.Option3
                  //             ? "correct-answer"
                  //             : ""
                  //         }
                  //       >
                  //         {pollData?.Option3}
                  //         {pollAnswer !== "" &&
                  //           (pollAnswer === correctAnswer ||
                  //             pollAnswer !== correctAnswer) &&
                  //           correctAnswer === pollData?.Option3 && (
                  //             <span className="correct-answer">
                  //               {pollData?.Option3} - Correct
                  //             </span>
                  //           )}
                  //         {pollAnswer !== correctAnswer &&
                  //           pollAnswer === pollData?.Option3 &&
                  //           " - (Your answer is incorrect)"}
                  //       </label>
                  //     </div>
                  //   )}
                  //   {pollData?.Option4 && pollData?.Option4 !== "" && (
                  //     <div className="mb-2">
                  //       <input
                  //         type="radio"
                  //         id="test4"
                  //         name="radio-group"
                  //         onChange={(e) => handleOptionChange(e)}
                  //         value={pollData?.Option4}
                  //       />
                  //       <label
                  //         for="test4"
                  //         className={
                  //           pollAnswer === pollData?.Option4 &&
                  //           pollAnswer !== correctAnswer
                  //             ? "wrong-answer"
                  //             : pollAnswer !== "" &&
                  //               (pollAnswer === correctAnswer ||
                  //                 pollAnswer !== correctAnswer) &&
                  //               correctAnswer === pollData?.Option4
                  //             ? "correct-answer"
                  //             : ""
                  //         }
                  //       >
                  //         {pollData?.Option4}
                  //         {pollAnswer !== "" &&
                  //           (pollAnswer === correctAnswer ||
                  //             pollAnswer !== correctAnswer) &&
                  //           correctAnswer === pollData?.Option4 && (
                  //             <span className="correct-answer"> - Correct</span>
                  //           )}
                  //         {pollAnswer !== correctAnswer &&
                  //           pollAnswer === pollData?.Option4 &&
                  //           " - (Your answer is incorrect)"}
                  //       </label>
                  //     </div>
                  //   )}
                  // </div>
                  // ) : (
                  <div>
                    {pollResultOnSubmit && correctAnswer && pollResultOnSubmit[0]?.CorrectAnswer !==null &&
                    pollResultOnSubmit[0]?.CorrectAnswer!=="" &&  (
                      <p className="correct-anser-label">
                        Correct Answer - 
                        {(pollResultOnSubmit[0]?. CorrectAnswer&& pollData.Option1) && pollResultOnSubmit[0]?. CorrectAnswer=== "1"?pollData.Option1:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option2) && pollResultOnSubmit[0]?. CorrectAnswer=== "2"?pollData.Option2:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option3) && pollResultOnSubmit[0]?. CorrectAnswer=== "3"?pollData.Option3:""}
                          {(pollResultOnSubmit[0]?. CorrectAnswer && pollData.Option4) && pollResultOnSubmit[0]?. CorrectAnswer=== "4"?pollData.Option4:""}
                      </p>
                    )}
                    {pollResultOnSubmit &&
                      totalPollResults > 0 &&
                      pollResultOnSubmit
                        ?.sort((a, b) => b.count - a.count)
                        .map((option) => (
                          <>
                            {option?.Answer && (
                              <React.Fragment key={option.Answer}>
                                <label className="option-label">{`${calculatePollPercentage(
                                  option?.count
                                )}% - ${option?.Answer}`}</label>
                                <div className="progressbar-gray">
                                  <span
                                    className="progressbar-purple"
                                    style={{
                                      width: `${calculatePollPercentage(
                                        option?.count
                                      )}%`,
                                    }}
                                  ></span>
                                </div>
                              </React.Fragment>
                            )}
                          </>
                        ))}
                  </div>
                )}
                {!isSubmitted && (
                  <button
                    className="btn btn__purple btn__subscribe w-100 position-relative min-ht-52"
                    isDisabled={pollAnswer === "" ? true : false}
                    onClick={() => {
                      handlePollAnswer();
                    }}
                  >
                    {isLoading ? <Spin indicator={antIcon} /> : "Submit"}
                  </button>
                )}
                {/* <div className="divider-line">
                {successMessage && successMessage !== "" && (
                  <p className="text-center mb-2 succes-msg-green">
                    {successMessage}
                  </p>
                )}
              </div> */}
              </>
            </div>
          </div>
        ) : (
          ""
        )}
        {isMounted && pollResult &&
          pollResult.length > 0 &&
          pollResult[0]?.Answer !== "" &&
          pollResult[0]?.Answer !== null &&
          activeTab == "Results" &&
          pollResultPresent && totalResults>0 ?(
            <div className="gray-card poll-result-h mb-0 ">
              <div className="box-border">
                <>
                  <h6>Results - Week Ending {previousWeekDate}</h6>

                  <p className="mb-3">{pollResult[0]?.Question}</p>
{pollResult[0]?.CorrectAnswer && (
                  <p className="correct-anser-label">
                    Correct Answer - {pollResult[0]?.CorrectAnswer}
                  </p>
)}
                </>
                <div>
                  {pollResult &&
                    totalResults > 0 &&
                    pollResult
                      ?.sort((a, b) => b.count - a.count)
                      .map((option) => (
                        <>
                          {option?.Answer && (
                            <React.Fragment key={option.Answer}>
                              <label className="option-label">{`${calculatePercentage(
                                option?.count
                              )}% - ${option?.Answer}`}</label>
                              <div className="progressbar-gray">
                                <span
                                  className="progressbar-purple"
                                  style={{
                                    width: `${calculatePercentage(
                                      option?.count
                                    )}%`,
                                  }}
                                ></span>
                              </div>
                            </React.Fragment>
                          )}
                        </>
                      ))}
                </div>

                {pollResult && pollResult.length > 0 && (
                  <>
                    {" "}
                    <p className="text-center mb-1">
                      Do you have a good poll question idea?
                    </p>
                    <a
                      href={`mailto:lmallon@liontree.com`}
                      className="anchor-links text-center"
                    >
                      Click here to submit
                    </a>
                  </>
                )}
              </div>
            </div>
          ):("")}
        {!pollDataPresent && isMounted && pollResult &&
        pollResult.length > 0 &&
        pollResult[0]?.Answer !== "" &&
        pollResult[0]?.Answer !== null &&
        pollResultPresent  && totalResults>0 
         ? (
          <div className="gray-card poll-result-h mb-0 ">
            <div className="box-border">
              <>
                <h6>Results - Week Ending {previousWeekDate}</h6>

                <p className="mb-3">{pollResult[0]?.Question}</p>
                {pollResult[0]?.CorrectAnswer && (
                <p className="correct-anser-label">
                  Correct Answer - {pollResult[0]?.CorrectAnswer}
                </p>
                )}
              </>
              <div>
                {pollResult &&
                  totalResults > 0 &&
                  pollResult
                    ?.sort((a, b) => b.count - a.count)
                    .map((option) => (
                      <>
                        {option?.Answer && (
                          <React.Fragment key={option.Answer}>
                            <label className="option-label">{`${calculatePercentage(
                              option?.count
                            )}% - ${option?.Answer}`}</label>
                            <div className="progressbar-gray">
                              <span
                                className="progressbar-purple"
                                style={{
                                  width: `${calculatePercentage(
                                    option?.count
                                  )}%`,
                                }}
                              ></span>
                            </div>
                          </React.Fragment>
                        )}
                      </>
                    ))}
              </div>

              {pollResult && pollResult.length > 0 && (
                <>
                  {" "}
                  <p className="text-center mb-1">
                    Do you have a good poll question idea?
                  </p>
                  <a
                    href={`mailto:lmallon@liontree.com`}
                    className="anchor-links text-center"
                  >
                    Click here to submit
                  </a>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      )}
      {loader ? (
        <>
          <h1 style={{ marginTop: 200 }}> </h1>
          <Spin
            size="small"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
            className="loader__full"
          />
        </>
      ) : keyNewsDataWithDate.length > 0 ? (
        <div className="gray-card gray-card-sticky lightgray-bg" ref={newsSectionRef}>
          <h3 className="mb-2">Our Daily Curated News Feed</h3>
          <div key={newsDate} className="current-date-news sticky">
            {" "}
            {newsDate
              ? newsDate == "Invalid date"
                ? moment(keyNewsDataWithDate[0]?.ItemName, "YYYY-MM-DD").format(
                    "MMM DD, YYYY"
                  )
                : newsDate
              : moment(keyNewsDataWithDate[0]?.ItemName, "YYYY-MM-DD").format(
                  "MMM DD, YYYY"
                )}
          </div>
          <div
            className={((pollData==null && pollResult[0]?.Question==null) || !isPollVisible)? "outer-scroll other-news-height":"outer-scroll"}
            style={{
              maxHeight: `calc(100vh - ${
                360 - divPollHeight - divPollResultHeight
              }px)`,
              overflowY: "auto",
            }}
          >
            <div
              ref={newsListRef}
              className="dynamic-content"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {keyNewsDataWithDate.map((dateObject, index) => (
                <div key={index}>
                  <div
                    className="current-date-news"
                    // ref={currentDateRef}
                    style={{
                      opacity: 0,
                      visibility: "hidden",
                      height: 0,
                      padding: 0,
                      margin: 0,
                    }}
                    key={newsDate}
                    // data-date-object={dateObject.ItemName}
                    ref={(el) => (currentDateRefs.current[index] = el)}
                  >
                    {dateObject.ItemName}
                  </div>
                  <div>
                    {dateObject.data.map((item, itemIndex) => (
                      <div
                        className="news-list-item"
                        key={itemIndex}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handledClicked(
                            "External Link -",
                            item?.ExternalUrl,
                            item,
                            getDescription(
                              item.LongDescription.replaceAll(
                                "<p>",
                                ""
                              ).replaceAll("</p>", "")
                            )
                          )
                        }
                      >
                        <div className="short-otherNews">
                          <div>
                            <img
                              key={itemIndex}
                              className={`image${itemIndex}`}
                              src={
                                item?.PhotoUrl && item?.PhotoUrl !== "" && item?.PhotoUrl.startsWith("https://")
                                  ? // imageStatus[itemIndex]
                                    // ?
                                    item?.PhotoUrl.replaceAll("&amp;", "&")
                                  : OtherNewsDefault
                              }
                              alt="other-news"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <Interweave
                          content={
                            getDescription(
                              item.LongDescription.replaceAll(
                                "<p>",
                                ""
                              ).replaceAll("</p>", "")
                            ) +
                            "." +
                            " " +
                            // `<a href = '${item?.ExternalUrl}' >(${item?.ExternalUrlSite})</a>`
                            `<a href = '#' >(${item?.ExternalUrlSite})</a>`
                          }
                          transform={(node, children) =>
                            transformText(
                              get(item, "ExternalUrl", ""),
                              getDescription(
                                item.LongDescription.replaceAll(
                                  "<p>",
                                  ""
                                ).replaceAll("</p>", "")
                              ),
                              node,
                              children
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {keyNewsData.length>0&&(
      <div className="text-center" style={{ 'background': '#F8F8F8'}}>
      <button
                        className="viewmore-news"
                         onClick={()=>navigate(`/LTWeekly/bysub-sector`)}
                        
                      >
                          View more curated news by Sub-Sector
                        
                      </button>
      </div>
    )}
    </div>
  );
}
