import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Drawer } from "antd";
import { get } from "lodash";
import Close from "../../components/icons/Close";
import Filter from "../../components/icons/Filter";
import moment from "moment";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Interweave } from "interweave";
import OtherNewsDefault from "../../images/other_news_default.png";
import { GridView, ListView } from "../../components/icons";
import themeUpdateImg from "../../images/lion-tree-weekly-update.png"
import downArrow from "../../images/down_arrow.svg"

import ReactModal from "react-modal";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function NewsComponent({
  categoryCount,
  tagsCount,
  getOtherNewsByCategories,
  SavedLTWebsiteAnalytics,
  getAllLTWeeklyDates,
  getLTWeeklyThemesCategories,
  getLTWeeklyAndOthersNewsCategories,
  CheckUserAuthenticity
}) {
  const [categories, setCategories] = useState([]);
  const [isFilterOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [count, setCount] = useState(25);
  const [otherNewsList, setOtherNewsList] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState("grid-view");
  const [isAnalyticSave, setIsAnalyticSave] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [sortKey, setSortKey] = useState(0);
  const [sortBy, setSortBy] = useState(true);
  const [isloadMore, setIsLoadMore] = useState(false);
  const [articleCategories, setArticleCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(["Topics", "LT Weeklies"]);
  const [showFullSummary, setShowFullSummary] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isbuttonClicked, setIsButtonClicked] = useState(false);
  const [imageStatus, setImageStatus] = useState([]);
  const [mailModalIsOpen,setMailModalIsOpen] =useState(false)
  const [successModalIsOpen,setSuccessModalIsOpen] =useState(false)
const [themeLink,setThemeLink]= useState("")
const [isEmailExists,setIsEmailExists]=useState(localStorage.getItem("t")?true:false)
const [userEmail,setUserEmail]= useState("")
const [errorMessage,setErrorMessage]=useState("")
const [expandedCategories, setExpandedCategories] = useState([]);
const [selectedCategories, setSelectedCategories] = useState([]);
const [emailloader,setEmailLoader]=useState(false)
const [isAcknwoledgmentModalOpen,setIsAcknwoledgmentModalOpen] = useState(false)
const [subcategories,setSubcategories]= useState([])
const [isCategoryClicked,setIsCategoryClicked]=useState([false,false,false,false,false,false])
  const location = useLocation();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const newsView = (val) => {
    setView(val);
  };
  // const showDrawer = () => {
  //   setIsDrawerOpen(true);
  // };
  // const closeDrawer = () => {
  //   setIsDrawerOpen(false);
  // };
  const pageViewLink = (actionDescription) => {
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "pageview",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
    };
    SavedLTWebsiteAnalytics(obj);
  };

  const handledClicked = (actionDescription, val, data) => {
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    actionDescription = actionDescription + " " + data.ExternalUrlSite;
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val?.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
    };
    // SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const loadMore = async () => {
    setIsLoadMore(true);
    let pageNumber = page + 1;

    if (
      (activeTab.includes("Topics") && activeTab.includes("LT Weeklies")) ||
      activeTab.length === 0
    ) {
      const { value } = await getLTWeeklyAndOthersNewsCategories({
        Page: pageNumber,
        Count: 25,
        Categories: categories,
        LtDates: [],
        SortKey: sortKey,
      });

      setOtherNewsList([...otherNewsList, ...get(value, "Results", [])]);
      setTotalCount(get(value, "totalCount", 0));
      setPage(pageNumber);
      setIsLoadMore(false);
      setIsLoading(false);
    } else if (activeTab.includes("Topics")) {
      const { value } = await getOtherNewsByCategories({
        Page: pageNumber,
        Count: count,
        Categories: categories,
        LtDates: [],
        SortKey: sortKey,
      });
      setTotalCount(get(value, "totalCount", 0));
      let concateData = [...otherNewsList, ...get(value, "Results", [])];
      setOtherNewsList([...concateData]);
      setPage(pageNumber);
      setIsLoadMore(false);
      setIsLoading(false);
    } else if (activeTab.includes("LT Weeklies")) {
      const { value } = await getLTWeeklyThemesCategories({
        Page: pageNumber,
        Count: 25,
        Categories: categories,
        Source: "",
        LtDates: [],
        SortKey: sortKey,
      });
      setTotalCount(get(value, "totalCount", 0));
      let concateData = [...otherNewsList, ...get(value, "Results", [])];
      setOtherNewsList([...concateData]);
      setPage(pageNumber);
      setIsLoadMore(false);
      setIsLoading(false);
    }
  };

  //for displaying image associated with news
  // const imageOk = async (image, imageTag) => {
  //   let oImg = new Image();
  //   oImg.src = image;
  //   let status = false;
  //   oImg.onload = async () => {
  //     // return true;
  //     status = true;
  //     return status;
  //   };
  //   oImg.onerror = async () => {
  //     status = false;

  //     // let image = document.querySelector(imageTag);
  //     // if (image) {
  //     //   image.src = OtherNewsDefault;
  //     // }
  //     return status;
  //   };
  // };

  const imageOk = async (image, index) => {
    let oImg = new Image();
    oImg.src = image;
    let status = false;

    oImg.onload = async () => {
      status = true;
      setImageStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = status;
        return newStatus;
      });
    };

    oImg.onerror = async () => {
      status = false;
      setImageStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = status;
        return newStatus;
      });
    };
  };

  useEffect(() => {
    if (!isMounted) {
      window.scrollTo({ top: 0 });
      setSortBy(true);
      if (!isAnalyticSave) {
        setIsAnalyticSave(true);
        pageViewLink("News Page");
      }
      // getLTWeeklyDates();
      setIsLoading(true);
      // if (location.search || activeTab.includes("Topics")) {
      //   const callApi = async () => {
      //     // setActiveTab("Topics");
      //     const { value } = await getOtherNewsByCategories({
      //       Page: page,
      //       Count: 25,
      //       Categories: categories.length > 0 ? categories : [],
      //       LtDates: [],
      //       SortKey: sortKey,
      //     });
      //     setTotalCount(get(value, "totalCount", 0));
      //     setOtherNewsList(get(value, "Results", []));
      //     setIsLoading(false);
      //   };
      //   callApi();
      // } else {
        const callApi = async () => {
          const { value } = await getLTWeeklyAndOthersNewsCategories({
            Page: page,
            Count: 25,
            Categories: categories.length > 0 ? categories : [],
            LtDates: [],
            SortKey: sortKey,
          });
          setTotalCount(get(value, "totalCount", 0));

          if (value) {
            setIsLoading(false);
          }
          setOtherNewsList(get(value, "Results", []));
        };

        callApi();
      // }
      setIsMounted(true);
    }

    // const combinedCounts = [...categoryCount, ...themesCount];
    // const uniqueCategories = {};

    // combinedCounts.forEach((data) => {
    //   const category = data.Category;
    //   const count = data.Count || 0;

    //   if (uniqueCategories[category]) {
    //     uniqueCategories[category].Count += count;
    //   } else {
    //     uniqueCategories[category] = { Category: category, Count: count };
    //   }
    // });

    // const mergedAndDeduplicatedCounts = Object.values(uniqueCategories);

    // // Set the combinedCategories state
    // setCombinedCategories([...mergedAndDeduplicatedCounts]);
  }, [otherNewsList, totalCount]);
 
  useEffect(() => {
    if (tagsCount.length > 0) {
      const allSubcategories = tagsCount.flatMap(header => header.Categories);
      setSubcategories(allSubcategories);
    }
  }, [tagsCount]);

  const getDescription = (a, i) => {
    let contentString = a;

    if (!a?.includes("<strong>")) {
      let b = a.slice(0, a.indexOf(". "));
      let c = a.slice(a.indexOf(". ") + 1, a.length);
      contentString = "<p><b>" + b + ". " + "</b>" + c + "</p>";
    }
    if (showFullSummary[i]) {
      return <Interweave content={stripTags(contentString)} />;
    } else if (contentString.length >= 160) {
      return (
        <div>
          <Interweave
            content={
              stripTags(contentString).slice(0, 160) +
              "...<a href='#'}>Full Summary</a>"
            }
            transform={(node, children) =>
              transformFullSummary(i, node, children)
            }
          />
        </div>
      );
    } else {
      return <Interweave content={stripTags(contentString)} />;
    }
  };

  const transformFullSummary = (i, node, children) => {
    if (
      node.tagName.toLowerCase() === "a" &&
      node.textContent === "Full Summary"
    ) {
      return (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            toggleFullSummary(i);
          }}
          style={{
            textDecoration: "underline",
            color: "#333",
            fontWeight: "500",
          }}
        >
          {children}
        </a>
      );
    }
  };
  const toggleFullSummary = (i) => {
    setShowFullSummary((prevShowFullSummary) => {
      const updatedShowFullSummary = [...prevShowFullSummary];
      updatedShowFullSummary[i] = !updatedShowFullSummary[i];
      return updatedShowFullSummary;
    });
  };
  const stripTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  // const linkClicked = (actionDescription, url, mainId) => {
  //   let email = localStorage.getItem("email");
  //   let itemDate = url.split("/")[2];
  //   let itemId = url.split("/")[3];
  //   itemId = itemId ? itemId : "";
  //   actionDescription = getThemeTitle(actionDescription);
  //   if (
  //     actionDescription !== "LTWeekly Detail" &&
  //     actionDescription !== "Stock Market Check" &&
  //     actionDescription !== "Key News by Sub-Sector" &&
  //     actionDescription !== "Intro"
  //   ) {
  //     actionDescription = "Theme Title - " + actionDescription;
  //   }
  //   itemDate =
  //     itemDate &&
  //     itemDate !== "archives" &&
  //     itemDate !== "about" &&
  //     itemDate !== undefined
  //       ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
  //       : "";
  //   let format = "MMMM DD, YYYY";
  //   let currentUtcTime = new Date();
  //   // Converts the UTC time to a locale specific format, including adjusting for timezone.
  //   let currentDateTimeCentralTimeZone = new Date(
  //     currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  //   );
  //   let dayNight =
  //     currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
  //   let currentDate = moment(new Date(), format).format(format);
  //   let currentHr = currentDateTimeCentralTimeZone.getHours();
  //   let currentMin = currentDateTimeCentralTimeZone.getMinutes();
  //   currentDate =
  //     currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
  //   itemId =
  //     actionDescription !== "LTWeekly Detail" &&
  //     actionDescription !== "Stock Market Check" &&
  //     actionDescription !== "Key News by Sub-Sector" &&
  //     actionDescription !== "Intro"
  //       ? itemId
  //       : "";
  //   let obj = {
  //     Date: currentDate,
  //     Url: window.location.host + url,
  //     ActionType: "clicked",
  //     NewsletterID: mainId,
  //     NewsletterDate: itemDate,
  //     ThemeID: itemId,
  //     ActionDescription: actionDescription,
  //     // Device: getDevice(),
  //     // Browser: browserName,
  //     ContactId:localStorage.getItem("ContactId")?localStorage.getItem("ContactId"):"",
  //     IsLive:localStorage.getItem("IsLive")?localStorage.getItem("IsLive"):"Yes",
  //   };
  //   SavedLTWebsiteAnalytics(obj);
  // };

  const changeCategory = async (indexOrCategory,index,calledFrom) => {
    window.scrollTo({ top: 0 });
    let updatedExpandedCategories;
    setIsLoading(true);
    setIsModalOpen(false);
    setPage(1);
    let category = indexOrCategory;
    let updatedCategories;
    let updatedArticleCategories;
    let updatedSelectedCategories;
    
    if (typeof indexOrCategory === "number") {
      if (indexOrCategory >= 0 && indexOrCategory < subcategories.length) {
        category = subcategories[indexOrCategory].Category;
      } else {
        console.error("Invalid index:", indexOrCategory);
        return;
      }
    } else {
      category = category.trimStart();
      category = category.trimEnd();
    }
    const isCategorySelected = categories.includes(category);
    // if (combinedCategories.length>0 ) {
    //   updatedCategories = combinedCategories?.filter((cat) => cat !== category);
    //   updatedArticleCategories = articleCategories?.filter((cat) => cat !== category);
    //   setCategories(updatedCategories);
    //   setArticleCategories(updatedArticleCategories);
    // } else {
    //   updatedCategories = [...categories, category];
    //   updatedArticleCategories = [...articleCategories, category];
    //   setCategories(updatedCategories);
    //   setArticleCategories(updatedArticleCategories);
    // }
    let ind = -1;
     if(calledFrom ==="news"){
    for(var j = 0;j<tagsCount?.length;j++){
      for(var i = 0;i<tagsCount[j]?.Categories?.length;i++){
        let cat = tagsCount[j]?.Categories[i]?.Category
        if(cat === category){
          ind = j;    
          break
        }
      }
    }
    if(ind != -1){
      setIsCategoryClicked(prevState => ({
        ...prevState,
        [ind]: !prevState[ind]
    }));
    }
   }
  

    if (isCategorySelected) {
      // If the category is selected, filter it out
      updatedCategories = categories.filter((cat) => cat !== category);
       updatedExpandedCategories = expandedCategories.filter((cat) => cat !== category)
      updatedSelectedCategories = selectedCategories.filter((cat) => cat !== category)
      // updatedArticleCategories = articleCategories.filter(
      //   (cat) => cat !== category
      // );
    } else {
      updatedCategories =[...categories, category];
      updatedSelectedCategories = [...selectedCategories, category];
      updatedExpandedCategories = [...expandedCategories, category]

      // updatedArticleCategories = [...articleCategories, category];
    }
   

    // setExpandedCategories(updatedExpandedCategories)
    setCategories(updatedCategories);
    setSelectedCategories(updatedSelectedCategories);
    setArticleCategories(updatedArticleCategories);
    if (
      (activeTab.includes("Topics") && activeTab.includes("LT Weeklies")) ||
      activeTab.length === 0
    ) {
      setIsButtonClicked(true);
      const { value } = await getLTWeeklyAndOthersNewsCategories({
        Page: 1,
        Count: 25,
        Categories: updatedCategories, // Use the updated state
        LtDates: [],
        SortKey: sortKey,
      });
      setOtherNewsList(get(value, "Results", []));
      setTotalCount(get(value, "totalCount", 0));
      setIsButtonClicked(false);
    } else if (activeTab.includes("Topics")) {
      setIsButtonClicked(true);
      const { value } = await getOtherNewsByCategories({
        Page: 1,
        Count: count,
        Categories: updatedCategories, // Use the updated state
        LtDates: await dateConvert(selectedDates),
        SortKey: sortKey,
      });
      setTotalCount(get(value, "totalCount", 0));
      setOtherNewsList(get(value, "Results", []));
      setIsButtonClicked(false);
    } else if (activeTab.includes("LT Weeklies")) {
      setIsButtonClicked(true);
      const { value } = await getLTWeeklyThemesCategories({
        Page: 1,
        Count: 25,
        Categories: updatedCategories, // Use the updated state
        Source: "",
        LtDates: await dateConvert(selectedDates),
        SortKey: sortKey,
      });
      setTotalCount(get(value, "totalCount", 0));
      setOtherNewsList(get(value, "Results", []));
      setIsButtonClicked(false);
    }
    setIsLoaded(true);
    setIsLoading(false);
  };

  const changeTab = async (tabName) => {
    let isbothActive = false;
    let updatedActiveTab = [...activeTab];
    const index = updatedActiveTab.indexOf(tabName);

    if (index > -1) {
      updatedActiveTab.splice(index, 1);
    } else if (updatedActiveTab?.length < 2) {
      updatedActiveTab.push(tabName);
    } else {
      isbothActive = true;
      updatedActiveTab = ["Topics", "LT Weeklies"];
    }
    if (updatedActiveTab?.length === 0) {
      updatedActiveTab = [];
    }

    setActiveTab(!isbothActive ? updatedActiveTab : ["Topics", "LT Weeklies"]);

    setPage(1);
    if (categories.length > 0) {
      // setArticleCategories(...articleCategories);
      setCategories([...categories]);
    } else {
      setArticleCategories([]);
      setCategories([]);
    }
    if (
      (updatedActiveTab.includes("Topics") &&
        updatedActiveTab.includes("LT Weeklies")) ||
      updatedActiveTab.length === 0
    ) {
      setIsLoading(true);
      setIsButtonClicked(true);
      const { value } = await getLTWeeklyAndOthersNewsCategories({
        Page: 1,
        Count: 25,
        Categories: categories?.length > 0 ? categories : [],
        LtDates: [],
        SortKey: sortKey,
      });
      setOtherNewsList(get(value, "Results", []));
      setTotalCount(get(value, "totalCount", 0));
      setIsLoading(false);
      setIsButtonClicked(false);
    } else if (updatedActiveTab.includes("LT Weeklies")) {
      setIsLoading(true);
      setIsButtonClicked(true);
      const { value } = await getLTWeeklyThemesCategories({
        Page: 1,
        Count: 25,
        Categories: categories?.length > 0 ? categories : [],
        Source: "",
        LtDates: [],
        SortKey: sortKey,
      });
      if (value) {
        setIsLoading(false);
      }
      setTotalCount(get(value, "totalCount", 0));
      setOtherNewsList(get(value, "Results", []));
      setIsButtonClicked(false);
    } else if (updatedActiveTab.includes("Topics")) {
      setIsLoading(true);
      setIsButtonClicked(true);
      const { value } = await getOtherNewsByCategories({
        Page: 1,
        Count: count,
        Categories: categories?.length > 0 ? categories : [],
        LtDates: [],
        SortKey: sortKey,
      });
      if (value) {
        setIsLoading(false);
      }
      setTotalCount(get(value, "totalCount", 0));
      setOtherNewsList(get(value, "Results", []));
      setIsButtonClicked(false);
    }
  };

  //   const handleSwitch = async (checked) => {
  //     setIsLoading(true);
  //     setSortBy(checked);
  //     setPage(1)
  //     const newSortKey = checked ? 0 : 1;
  //     setSortKey(newSortKey);
  //     if ((activeTab.includes("Topics") && activeTab.includes("LT Weeklies"))||(activeTab.length===0)) {

  //     if (checked) {
  //       const { value } = await getLTWeeklyAndOthersNewsCategories({
  //         Page: 1,
  //         Count: 21,
  //         Categories: categories,
  //         LtDates: [],
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []))

  //     }
  //   else{
  //     const { value } = await getLTWeeklyAndOthersNewsCategories({

  //         Page: 1,
  //         Count:  21,
  //         Categories: categories,
  //         LtDates: await dateConvert(selectedDates),
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []))
  //     }
  //   }
  //   else if (activeTab.includes("Topics")) {
  //     if (checked) {
  //       const { value } = await getOtherNewsByCategories({
  //         Page: 1,
  //         Count: count,
  //         Categories: categories,
  //         LtDates: [],
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     } else {
  //       const { value } = await getOtherNewsByCategories({
  //         Page: 1,
  //         Count: count,
  //         Categories: categories,
  //         LtDates: await dateConvert(selectedDates),
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     }
  //   }
  //   else if (activeTab.includes("LT Weeklies")) {
 
  //     if (checked) {
  //       const { value } = await getLTWeeklyThemesCategories({
  //         Page: 1,
  //         Count: 21,
  //         Categories: articleCategories,
  //         Source:"",
  //         LtDates: [],
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     } else {
  //       const { value } = await getLTWeeklyThemesCategories({
  //         Page: 1,
  //         Count: 21,
  //         Categories: articleCategories,
  //         Source:"",
  //         LtDates: await dateConvert(selectedDates),
  //         SortKey: newSortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     }
  //   }
  //   setIsLoading(false);
  // }
  // const getLTWeeklyDates = async () => {
  //   const { value } = await getAllLTWeeklyDates();
  //   setFilteredDates(get(value, "Result", []));
  //   setAllLTWeeklyDates(get(value, "Result", []));
  //   if (value) {
  //     setIsLoading(false);
  //   }
  // };

  //for selecting dates in the Filter By LT weekly drawer
  // const handlechangeDates = async (i, date) => {
  //   setPage(1)
  //   // Calculate the updatedSelectedDates within the callback
  //   setSelectedDates((prevSelectedDates) => {
  //     const updatedSelectedDates = prevSelectedDates.includes(date)
  //       ? prevSelectedDates.filter((selectedDate) => selectedDate !== date)
  //       : [...prevSelectedDates, date];
  //     return updatedSelectedDates;
  //   });

  //   // Store the updatedSelectedDates in a separate variable
  //   const updatedSelectedDates = selectedDates.includes(date)
  //     ? selectedDates.filter((selectedDate) => selectedDate !== date)
  //     : [...selectedDates, date];

  //   await makeApiCall(updatedSelectedDates);
  // };

  //   const makeApiCall = async (updatedSelectedDates) => {
  //     const assignedDateNew=await dateConvert(updatedSelectedDates)
  //     if(activeTab==="Topics"){
  //       const { value } = await GetCategoriesAndCount({assignedDate:assignedDateNew?.join('|')});
  //       setCategoryCount(get(value, "CatResults", []));
  //     if (sortKey === 0) {
  //       const { value } = await getOtherNewsByCategories({
  //         Page: 1,
  //         Count: count,
  //         Categories: categories,
  //         LtDates: assignedDateNew,
  //         SortKey: sortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     } else if (sortKey === 1) {

  //       const { value } = await getOtherNewsByCategories({
  //         Page: 1,
  //         Count: count,
  //         Categories: categories,
  //         LtDates: assignedDateNew,
  //         SortKey: sortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     }
  //   } else{
  //     const { value } = await GetArticalCategoriesCount({assignedDate:assignedDateNew?.join('|')});
  //     setThemesCount(get(value, "CatResults", []));
  //     if (sortKey === 0) {
  //       const { value } = await getLTWeeklyThemesCategories({
  //         Page: 1,
  //         Count: 21,
  //         Categories: articleCategories,
  //         Source:"",
  //         LtDates: assignedDateNew,
  //         SortKey: sortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     } else if (sortKey === 1) {
  //       const { value } = await getLTWeeklyThemesCategories({
  //         Page: 1,
  //         Count: 21,
  //         Categories: articleCategories,
  //         LtDates: assignedDateNew,
  //         SortKey: sortKey,
  //       });
  //       setTotalCount(get(value, "totalCount", 0));
  //       setOtherNewsList(get(value, "Results", []));
  //     }
  //   }
  // }

  //for searching any date in Filter By LT weekly Drawer
  // const handleSearchDateText = async (e) => {
  //   const searchText = e.target.value.toLowerCase();

  //   // Filter dates based on search text
  //   const filteredDates = allLTWeeklyDates.filter((item) => {
  //     return item.Date.toLowerCase().includes(searchText);
  //   });

  //   // If search text is empty, revert to all dates
  //   setFilteredDates(searchText === "" ? allLTWeeklyDates : filteredDates);

  //   setSearchDateText(e.target.value);
  // };

  //making options to display in multiselect
  // const selectOptions = selectedDates.map((date) => ({
  //   value: date,
  //   label: date.replace("LT Weekly: ", ""),
  // }));
  // if (selectedDates.length === 0) {
  //   selectOptions.push({ value: "All", label: "All" });
  // }

  // const maxSelectedOptions = 2;
  // const handleOptionChange = async (option) => {
  //   let data = option
  //   let findAll = data?.findIndex((s) => s?.value !== 'All');
  //   if (findAll !== -1) {
  //     setIsAllOptionDisabled(true);
  //   }
  //   if (data?.length == 0) {
  //     setIsAllOptionDisabled(false);
  //     setIsOptionDisabled(false);
  //   } else {
  //     data?.map((item) => {
  //       return item.value === 'All'
  //         ? setIsOptionDisabled(!isOptionDisabled)
  //         : setIsOptionDisabled(false);
  //     });
  //   }

  //   setSelectedOption(finalOption)
  //   if(selectedOption ==='All'){
  //     const { value: firstApiValue } = await getOtherNewsByCategories({
  //       Page: page,
  //       Count: 12,
  //       Categories: categories,
  //       LtDates: [],
  //       SortKey: sortKey,
  //     });
  //     const firstApiResults = get(firstApiValue, "Results", []);

  //     const { value: secondApiValue } = await getLTWeeklyThemesCategories({
  //       Page: page,
  //       Count: 9,
  //       Categories: articleCategories,
  //       Source: "",
  //       LtDates: [],
  //       SortKey: sortKey,
  //     });
  //     const secondApiResults = get(secondApiValue, "Results", []);

  //     const combinedData = [...otherNewsList, ...firstApiResults, ...secondApiResults];
  //   combinedData.sort((a, b) => new Date(b.Created) - new Date(a.Created ))
  //     setOtherNewsList(combinedData);
  //   }
  //   else if(selectedOption ==='OtherNews'){
  //     setArticleCategories([])
  //     setCategories([])
  //     const { value } = await getOtherNewsByCategories({
  //       Page: page,
  //       Count: count,
  //       Categories: categories,
  //       LtDates: [],
  //       SortKey: sortKey,
  //     });
  //     setTotalCount(get(value, "totalCount", 0));
  //     setOtherNewsList(get(value, "Results", []));
  // }else if (selectedOption ==='Themes'){
  //   setArticleCategories([])
  //   setCategories([])
  //   const { value } = await getLTWeeklyThemesCategories({
  //     Page: page,
  //     Count: 21,
  //     Categories: articleCategories,
  //     Source:"",
  //     LtDates: [],
  //     SortKey: sortKey,
  //   });
  //   setTotalCount(get(value, "totalCount", 0));
  //   setOtherNewsList(get(value, "Results", []));
  // }
  // };

  //converts selected date into a format that can be used in api call
  const dateConvert = async (date) => {
    let d = date.map((a) => {
      return moment(new Date(a.replaceAll("LT Weekly:")), "MM/DD/YYYY").format(
        "MM-DD-YYYY"
      );
    });
    await Promise.all(d);
    return d === "" ? [] : d;
  };
  const getHrefUrl = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const linkElement = doc.querySelector("a");

    // Fetch the href value
    const hrefValue = linkElement?.getAttribute("href");
    return hrefValue;
  };
  const redirectToLink = (link) => {
    const updatedLink = link.replaceAll("?t=","")
    window.open(updatedLink, "_blank");
  };

  const getThemeTitle = (title) => {
    if (title?.includes("<span")) {
      title = title?.split("<span>")[1];
      title = title?.split("</span>")[0];
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title?.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title?.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;

          if (title?.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title?.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title?.includes("<a")) {
      if (title?.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title?.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };
  const transformText = (description, node, children) => {
    if (node.tagName.toLowerCase() === "a") {
      // description = node.textContent;
      const isReadMore = node.textContent === "Read More";
      const colorStyle = isReadMore
        ? { color: "#333", fontWeight: "500" }
        : { color: "blue" };
      return (
        <a
          href={node.getAttribute("href")}
          target="_blank"
          style={{ textDecoration: "underline", ...colorStyle }}
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
  };

  function truncateAndAddReadMore(text, maxLength, data) {
    const plainText = text.replace(/<[^>]+>/g, "");

    const truncatedText =
      plainText.length > maxLength
        ? plainText.slice(0, maxLength) + " ..."
        : plainText;

    const readMoreLink = `<a href="${getHrefUrl(data)}">Read More</a>`;
    return `${truncatedText} ${readMoreLink}`;
  }
  useEffect(() => {
    if (otherNewsList.length > 0) {
      otherNewsList.forEach((data, i) => {
        if (data.Type === 2 && data.PhotoUrl && data.PhotoUrl !== "") {
          imageOk(data.PhotoUrl.replaceAll("&amp;", "&"), i);
        }
      });
    }
  }, [otherNewsList]);
  const closeMailModal = () => {
    setMailModalIsOpen(false)
    setErrorMessage("")
    setThemeLink("")
    setUserEmail("")
   };
   const openMailModal = (getLink) => {
     setThemeLink(getLink)
     setMailModalIsOpen(true)
   };
   const closeSuccessModal = () => {
    setUserEmail("")
    setSuccessModalIsOpen(false)
   };
   const openSuccessModal = () => {
     setSuccessModalIsOpen(true)
   };
   const checkUser= async (email,link)=>{
     if(email===""){
       setErrorMessage('Please enter email')
       return
     }
     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     if (!regEmail.test(email)) {
       setErrorMessage('Invalid Email Address')
       return
     }
     let obj ={
       email:email,
       link:link?link:"",
     }
     setEmailLoader(true)
     const { value } = await CheckUserAuthenticity(obj)
  
     if (value?.Success){
      setEmailLoader(false)
      if(value.Message==="User is present, Send Magic Link!!"){
        setIsEmailExists(localStorage.getItem("t")?true:false)
        openSuccessModal()
        }
        else{
          opneAcknwoledgmentModal()
        }
     }else{
      setEmailLoader(false)
       window.location.href = 'https://lionrb1dev.wpengine.com/iimform?inboundpath=themeweb'
       setUserEmail("")
       setMailModalIsOpen(false)
     }
     setMailModalIsOpen(false)
     setErrorMessage("")
   }
   const handleChangeEmail =(email) =>{
     const userEmail=email
     setUserEmail(userEmail)
     setErrorMessage("")
   }
   
   const toggleCategory = (headerCategory, index) => {
    setIsCategoryClicked((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
    }));

    setExpandedCategories((prev) =>
        prev.includes(headerCategory)
            ? prev.filter((cat) => cat !== headerCategory)
            : [...prev, headerCategory]
    );
};


  
  const handleHeaderCheckboxChange = async (header,index) => {
    window.scrollTo({ top: 0 });
    setIsModalOpen(false);
    setPage(1);
    const allSubCategories = header.Categories.map(cat => cat.Category);
    // const isSelected = selectedCategories.some(cat => header.Categories.map(sub => sub.Category).includes(cat));
    const isHeaderChecked = allSubCategories.every(cat => selectedCategories.includes(cat));
    // Update the selected categories state
    setSelectedCategories((prev) => {
      const allSubCategories = header.Categories.map(cat => cat.Category);
    
      let updatedCategories;
      if (isHeaderChecked) {
        updatedCategories = prev.filter((cat) => !allSubCategories.includes(cat));
      } else {
        updatedCategories = [...prev, ...allSubCategories];
      }
      return updatedCategories;
    });
    setCategories((prev) => {
      let updatedCategories;
      if (isHeaderChecked) {
        updatedCategories = prev.filter((cat) => !allSubCategories.includes(cat));
      } else {
        updatedCategories = [...prev, ...allSubCategories];
      }
    fetchData(updatedCategories);
    return updatedCategories
    })
    setIsCategoryClicked((prevState) => ({
      ...prevState,
      [index]: !isHeaderChecked, // Expand when checked, collapse when unchecked
  }));

  setExpandedCategories((prev) =>
      isHeaderChecked
          ? prev.filter((cat) => cat !== header.HeaderCategory) // Collapse when unchecked
          : [...prev, header.HeaderCategory] // Expand when checked
  );
  };


  const fetchData = async (categories) => {
    setIsLoading(true);
    if (
      (activeTab.includes("Topics") && activeTab.includes("LT Weeklies")) ||
      activeTab.length === 0
    ) {
      setIsButtonClicked(true);
    const   { value } = await getLTWeeklyAndOthersNewsCategories({
        Page: 1,
        Count: 25,
        Categories: categories,
        LtDates: [],
        SortKey: sortKey,
      });
      setOtherNewsList(get(value, "Results", []));
    setTotalCount(get(value, "totalCount", 0));
    } else if (activeTab.includes("Topics")) {
      setIsButtonClicked(true);
     const { value} = await getOtherNewsByCategories({
        Page: 1,
        Count: 25,
        Categories: categories,
        LtDates: await dateConvert(selectedDates),
        SortKey: sortKey,
      });
      setOtherNewsList(get(value, "Results", []));
    setTotalCount(get(value, "totalCount", 0));
    } else if (activeTab.includes("LT Weeklies")) {
      setIsButtonClicked(true);
      const { value } = await getLTWeeklyThemesCategories({
        Page: 1,
        Count: 25,
        Categories: categories,
        Source: "",
        LtDates: await dateConvert(selectedDates),
        SortKey: sortKey,
      });
      setOtherNewsList(get(value, "Results", []));
    setTotalCount(get(value, "totalCount", 0));
    }
    setIsButtonClicked(false);
    setIsLoading(false);
  };
  const opneAcknwoledgmentModal =()=>{
    setIsAcknwoledgmentModalOpen(true)
  }
  const closeAcknowledgmentModal=()=>{
    setIsAcknwoledgmentModalOpen(false)
  }
  const isActive = (header) => {
    return( 
    expandedCategories.includes(header.HeaderCategory) || header.Categories?.findIndex((c)=>categories.includes(c.Category)) !== -1
  ) // header.Categories.some((c) => categories.includes(c.Category));
  };
  return (
    <>
      <div className="news-filter-for-mobile">
        <button className="btn btn__purple w-100" onClick={setIsModalOpen}>
          <Filter /> Filter by Topic
          {categories.length > 0 ? <>({categories.length})</> : ""}
        </button>
      </div>
      <section className="news-section">
        <div className="container">
          <div className="news-main">
            <div className="news-left sticky-top">
              {/* <div className="news-topics-wrapper">
                <div className="news-topics-dropdown">dropdowndfsgsdfsgfsggsgsdssd  <img className="down-arrow-icon active" src={downArrow} alt=""/> </div>
                <ul className="news-topics-list">
                  {categoryCount.length > 0 &&
                    categoryCount
                      .sort((a, b) => a?.Category?.localeCompare(b?.Category))
                      .map((data, i) => {
                        if (data.Count) {
                          return (
                            <li key={i}>
                              <label className="custom__checkbox">
                                {data.Category}
                                <input
                                  id={i}
                                  onChange={() => changeCategory(i)}
                                  checked={categories.includes(data.Category)}
                                  type="checkbox"
                                  className="custom__checkbox--input"
                                />
                                <span className="custom__checkbox--check"></span>
                              </label>
                            </li>
                          );
                        }
                      })}
                </ul>
              </div> */}
              <div className="news-topics-wrapper">
      {tagsCount.map((header, index) => {
        const allSubCategories = header.Categories.map(cat => cat.Category);
        const isHeaderChecked = allSubCategories.every(cat => selectedCategories.includes(cat));
      // const isActive = expandedCategories.includes(header.HeaderCategory) || header.Categories?.findIndex((c)=>categories.includes(c.Category)) !== -1;
      const isClicked = isCategoryClicked[index];
      // const isActive = expandedCategories.includes(header.HeaderCategory);
        return (
          <div key={index} className="news-topic">
            <div 

            // className="news-topics-dropdown"
            className={`news-topics-dropdown ${isActive(header) && isClicked ? 'active' : ''}`}
             onClick={(e) => {
              e.stopPropagation()
              toggleCategory(header.HeaderCategory,index)}}>
              <label className="custom__checkbox" 
              // onClick={(e) => e.stopPropagation()}
              >
                {header.HeaderCategory}
                <input
                  id={`header-${index}`}
                  onChange={(e) => {
                    e.stopPropagation()
                    // toggleCategory(header.HeaderCategory,index)
                    handleHeaderCheckboxChange(header,index);
                  }}
                  checked={isHeaderChecked}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
              </label>
              <img
                // className={`down-arrow-icon ${expandedCategories.includes(header.HeaderCategory) ? 'active' : ''}`}
                className={`down-arrow-icon ${isActive(header) && isClicked ? 'active' : ''}`}
                src={downArrow}
                alt=""
              />
            </div>
           
            {(isActive(header) && isClicked)  && (
              // 
              <ul className="news-topics-list">
                {header.Categories.length > 0 &&
                  header.Categories
                    .sort((a, b) => a?.Category?.localeCompare(b?.Category))
                    .map((data, i) => {
                        return (
                          <li key={i}>
                            <label className="custom__checkbox">
                              {data.Category}
                              <input
                                id={`sub-${index}-${i}`}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  changeCategory(data.Category, index,"list")}}
                                checked={categories.includes(data.Category)}
                                // checked={selectedCategories.includes(data.Category)}
                                type="checkbox"
                                className="custom__checkbox--input"
                              />
                              <span className="custom__checkbox--check"></span>
                            </label>
                          </li>
                        );
                    })}
              </ul>
            )}
          </div>
        );
      })}
    </div>
            </div>

            <div className="news-right position-relative">
              <div className="news-view-swicher justify-content-between align-items-end">
                <div className="d-flex align-items-center">
                  <span
                    className="news-view-swicher__label graphic-fonts"
                    style={{ paddingRight: "15px", alignItems: "self-end" }}
                  >
                    <span className="filter-label">Filter By LT Content:</span>
                    <span className="button-flex">
                      <button
                        disabled={isbuttonClicked}
                        className={`tab-outline ms-4 me-3 ${
                          activeTab.includes("LT Weeklies")
                            ? "tab-filled activetab"
                            : "tab-outline"
                        }`}
                        onClick={() => changeTab("LT Weeklies")}
                      >
                        {" "}
                        LT Weeklies
                      </button>
                      <button
                        disabled={isbuttonClicked}
                        className={`tab-outline ${
                          activeTab.includes("Topics")
                            ? "tab-filled activetab"
                            : "tab-outline"
                        }`}
                        onClick={() => changeTab("Topics")}
                      >
                        Key News
                      </button>
                    </span>
                  </span>
                </div>
                <div className="d-flex align-btm-mobile">
                  {/* <div className="switch-filter">
                        <span
                          className={`news-view-swicher__label  graphic-fonts ${
                            !sortBy ? "purple-text date_label" : "grey-text"
                          }`}
                          style={{ paddingRight: "15px" }}
                        >
                          Sort by Topics
                        </span>
                        <Switch
                          defaultChecked={sortBy ? true : false}
                          onChange={(e) => handleSwitch(e)}
                          className="news-view-swicher__switch date_label"
                        />
                        <span
                          className={`news-view-swicher__label  graphic-fonts ${
                            sortBy ? "purple-text date_label" : "grey-text"
                          }`}
                          style={{ paddingLeft: "15px" }}
                        >
                          Sort by Dates
                        </span>
                      </div> */}

                  <button
                    onClick={() => newsView("grid-view")}
                    className={
                      view === "grid-view"
                        ? "btn btn-grid-view active pr-0"
                        : "btn btn-grid-view pr-0"
                    }
                  >
                    <GridView />
                  </button>
                  <button
                    onClick={() => newsView("list-view")}
                    className={
                      view === "list-view"
                        ? "btn btn-list-view active pr-0"
                        : "btn btn-list-view pr-0"
                    }
                  >
                    <ListView />
                  </button>
                </div>
              </div>
              {isLoading ? (
                <div className="position-relative">
                  <h1 style={{ marginTop: 200 }}> </h1>
                  <Spin
                    size="large"
                    className="loader__full"
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 30,
                          color: "#000",
                          alignItems: "center",
                        }}
                        spin
                      />
                    }
                    // className="loader__full"
                  />
                </div>
              ) : (
                <>
                  {view === "grid-view" ? (
                    <div className="news-grid">
                      {otherNewsList.length > 0 &&
                        otherNewsList.map((data, i) => {
                          if (data.Type === 2) {
                            return (
                              <div className="news-grid-item" key={i}>
                                <div className="news-card">
                                  <div
                                    onClick={() =>
                                      handledClicked(
                                        "External Link -",
                                        data?.ExternalUrl,
                                        data,
                                        true
                                      )
                                    }
                                    className="news-thumb"
                                  >
                                    <img
                                      style={{
                                        // backgroundImage: imageOk(
                                        //   data.PhotoUrl?.replaceAll("&amp;", "&"),
                                        //   `.image${i}`,
                                        // )
                                        //   ? ""
                                        //   : `url(${OtherNewsDefault})`,
                                        // backgroundImage: imageStatus[i] ? '' : `url(${OtherNewsDefault})`,
                                        backgroundSize: `cover`,
                                        backgroundPosition: `center`,
                                      }}
                                      className={`image${i}`}
                                      // src={
                                      //   data.PhotoUrl &&
                                      //   data.PhotoUrl !== "" &&
                                      //   imageOk(
                                      //     data.PhotoUrl?.replaceAll("&amp;", "&"),
                                      //     `.image${i}`,
                                      //   )
                                      //     ? data.PhotoUrl?.replaceAll(
                                      //         "&amp;",
                                      //         "&",
                                      //       )
                                      //     : OtherNewsDefault
                                      // }
                                      src={
                                        (data.PhotoUrl &&
                                        data.PhotoUrl !== "" )
                                         && imageStatus[i]
                                          ? data.PhotoUrl.replaceAll(
                                              "&amp;",
                                              "&"
                                            )
                                          : OtherNewsDefault
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="content-details">
                                    <div className="news-info">
                                      <div className="news-subtitle">
                                        {data.Category?.replaceAll("|", " | ")}
                                        {data.SecondaryCategory
                                          ? " | " +
                                            data.SecondaryCategory.replaceAll(
                                              "|",
                                              " | "
                                            )
                                          : data.SecondaryCategory}{" "}
                                      </div>
                                      <div className="news-grid-dates">
                                        {moment(
                                          new Date(data.Created),
                                          "MM DD YYYY"
                                        ).format("MMMM DD, YYYY")}{" "}
                                        {data.ExternalUrlSite ? " | " : ""}{" "}
                                        {data.ExternalUrlSite}
                                      </div>
                                      <div className="news-heading">
                                        <h1 className="news-title">
                                          <a
                                            className="news-title"
                                            // onClick={() =>
                                            //   handledClicked(
                                            //     "External Link -",
                                            //     data.ExternalUrl,
                                            //     data
                                            //   )
                                            // }
                                            href={data?.ExternalUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {data.ShortDescription}
                                          </a>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <div className="news-grid-card">
                                  <div
                                    onClick={() => {
                                      // linkClicked(
                                      //   get(data, "ShortDescription", ""),
                                      //   `/LTWeekly/${moment(
                                      //     data.ItemName,
                                      //     "MM DD YYYY"
                                      //   ).format("MMMM-DD-YYYY")}/${
                                      //     data?.ItemId
                                      //   }`,
                                      //   ""
                                      // )

                                      // window.open(
                                      //   `${
                                      //     process.env.REACT_APP_SITE_URL +
                                      //     "/LTWeekly/" +
                                      //     moment(
                                      //       new Date(data.ItemName)
                                      //     ).format("MMMM-DD-yyyy") +
                                      //     "/" +
                                      //     data.LtweeklyId
                                      //   }`,
                                      //   "_blank"
                                      // );
                                      if(!localStorage.getItem("t")){
                                        openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                          data.ItemName,
                                          "MM DD YYYY"
                                        ).format("MMMM-DD-YYYY")}/${
                                          data?.LtweeklyId
                                        }`)
                                      }else{
                                      window.open(
                                        `/LTWeekly/${moment(
                                          new Date(data.ItemName)
                                        ).format("MMMM-DD-yyyy")}/${
                                          data.LtweeklyId
                                        }`,
                                        "_blank"
                                      );

                                      // redirectToLink(
                                      //   getHrefUrl(data.ShortDescription)
                                      // );
                                    }}}
                                    className="news-thumb-grid"
                                  >
                                    <img
                                      style={{
                                        backgroundSize: `cover`,
                                        backgroundPosition: `center`,
                                        border:"5px solid #4D1B6C"
                                      }}
                                      className="image"
                                      src={
                                        !data.PhotoUrl
                                          ? ""
                                          : data.PhotoUrl?.includes(
                                              "westus2storpublic"
                                            )
                                          ? data.PhotoUrl
                                          : process.env.REACT_APP_API_HOSTNAME +
                                            data.PhotoUrl
                                      }
                                      alt="asa"
                                    />
                                    <div className="theme-update">
                                      <img src={themeUpdateImg}/>
                                    </div>
                                  </div>

                                  <div className="content-details">
                                    {data.Category ||
                                    data.LTWeeklyThemeCategoryTitles ||
                                    data.SecondaryCategory ? (
                                      <div className="news--block mb-0 border-bottom-0">
                                        {" "}
                                        {data.Category ||
                                        data.LTWeeklyThemeCategoryTitles ? (
                                          <p className="purple-font cursor-auto">
                                            {data.Category}{" "}
                                            {data.Category &&
                                            (data?.LTWeeklyThemeCategoryTitles ||
                                              data?.SecondaryCategory)
                                              ? " | "
                                              : ""}{" "}
                                            {data?.LTWeeklyThemeCategoryTitles
                                              ? data?.LTWeeklyThemeCategoryTitles
                                              : data?.SecondaryCategory}{" "}
                                          </p>
                                        ) : data?.LTWeeklyThemeCategoryTitles ||
                                          data?.SecondaryCategory ? (
                                          data?.LTWeeklyThemeCategoryTitles ? (
                                            <p className="purple-font cursor-auto">
                                              {
                                                data?.LTWeeklyThemeCategoryTitles
                                              }{" "}
                                            </p>
                                          ) : (
                                            <p className="purple-font">
                                              {data?.SecondaryCategory}
                                            </p>
                                          )
                                        ) : (
                                          <p className="purple-font"></p>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="news--block mb-0 border-bottom-0"></div>
                                    )}{" "}
                                    <p className="news-grid-para">
                                      {data.ItemName !== "" &&
                                        data.ItemName !== null &&
                                        moment(
                                          data.ItemName,
                                          "MM-DD-YYYY"
                                        ).format("DD MMM, YYYY")}{" "}
                                      LT Weekly
                                    </p>
                                    <div
                                      className={
                                        data?.Category ||
                                        data?.LTWeeklyThemeCategoryTitles
                                          ? "news-grid-heading georgia-font"
                                          : "news-grid-heading-full georgia-font"
                                      }
                                        onClick={() =>{
                                        //   linkClicked(
                                        //   get(data, "ShortDescription", ""),
                                        //   `/LTWeekly/${moment(
                                        //     data.ItemName,
                                        //     "MM DD YYYY"
                                        //   ).format("MMMM-DD-YYYY")}/${
                                        //     data?.ItemId
                                        //   }`,
                                        //   ""
                                        // )
                                        if(!localStorage.getItem("t")){
                                          openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                            data.ItemName,
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${
                                            data?.LtweeklyId
                                          }`)
                                        }else{
                                        redirectToLink(getHrefUrl(data.ShortDescription))

                                      }}
                                        }
                                    >
                                      <a
                                        // href={'#'}
                                        // href={`${
                                        //   process.env.REACT_APP_SITE_URL +
                                        //   "/LTWeekly/" +
                                        //   moment(
                                        //     new Date(data.ItemName)
                                        //   ).format("MMMM-DD-yyyy") +
                                        //   "/" +
                                        //   data.LtweeklyId
                                        // }`}
                                        // href={`/LTWeekly/${moment(
                                        //   new Date(data.ItemName)
                                        // ).format("MMMM-DD-yyyy")}/${
                                        //   data.LtweeklyId
                                        // }`}
                                        className="news-title"
                                        // target="_blank"
                                        // rel="noopener noreferrer"
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {/* {getThemeTitle(data.ShortDescription)} */}
                                        {data.ShortDescription?.includes(
                                          "<a"
                                        ) ? (
                                          getThemeTitle(data.ShortDescription)
                                        ) : (
                                          <>
                                            {data.ShortDescription.replaceAll(
                                              "<span>",
                                              ""
                                            ).replaceAll("</span>", "")}
                                          </>
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <div className="news-list">
                      {otherNewsList.length > 0 &&
                        otherNewsList.map((data, i) => {
                          if (data.Type === 2) {
                            return (
                              <div className="news-list-item" key={i}>
                                <div className="news-row no-flex-wrap">
                                  <div className="news-col leftimg-sec">
                                    <div
                                      onClick={() =>
                                        handledClicked(
                                          "External Link -",
                                          data.ExternalUrl,
                                          data,
                                          true
                                        )
                                      }
                                      className="news-thumb"
                                    >
                                      <img
                                        style={{
                                          // backgroundImage: imageOk(
                                          //   data.PhotoUrl?.replaceAll(
                                          //     "&amp;",
                                          //     "&",
                                          //   ),
                                          //   `.image${i}`,
                                          // )
                                          //   ? ""
                                          //   : `url(${OtherNewsDefault})`,
                                          // backgroundImage: imageStatus[i] ? '' : `url(${OtherNewsDefault})`,
                                          backgroundSize: `cover`,
                                          backgroundPosition: `center`,
                                        }}
                                        className={`image${i}`}
                                        // src={
                                        //   data.PhotoUrl &&
                                        //   data.PhotoUrl !== "" &&
                                        //   imageOk(
                                        //     data.PhotoUrl?.replaceAll(
                                        //       "&amp;",
                                        //       "&",
                                        //     ),
                                        //     `.image${i}`,
                                        //   )
                                        //     ? data.PhotoUrl?.replaceAll(
                                        //         "&amp;",
                                        //         "&",
                                        //       )
                                        //     : OtherNewsDefault
                                        // }
                                        src={
                                             (data.PhotoUrl &&
                                        data.PhotoUrl !== "" )
                                        && imageStatus[i]
                                            ? data.PhotoUrl.replaceAll(
                                                "&amp;",
                                                "&"
                                              )
                                            : OtherNewsDefault
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="news-col rightimg-sec">
                                    <div className="news-info">
                                      <div className="news-info-block">
                                        <div className="news-subtitle">
                                          {/* {data.Category?.replaceAll("|", " | ")} */}
                                          {data.Category &&
                                            data?.Category?.split("|").map(
                                              (category, index) => (
                                                <React.Fragment key={index}>
                                                  {index > 0 && " | "}
                                                  <span
                                                    onClick={() =>
                                                      changeCategory(category,index,"news")
                                                    }
                                                  >
                                                    {category.replaceAll(
                                                      "|",
                                                      " | "
                                                    )}
                                                  </span>
                                                </React.Fragment>
                                              )
                                            )}
                                          {data.SecondaryCategory &&
                                            data?.SecondaryCategory?.split(
                                              "|"
                                            ).map((category, index) => (
                                              <React.Fragment key={index}>
                                                {index > 0 && " | "}
                                                <span
                                                  onClick={() =>
                                                    changeCategory(category,index,"news")
                                                    
                                                  }
                                                >
                                                  {category.replaceAll(
                                                    "|",
                                                    " | "
                                                  )}
                                                </span>
                                              </React.Fragment>
                                            ))}
                                        </div>
                                        <div className="news-subtitle news-subtitle-right custom-flex-wrap default-cursor">
                                          {moment(
                                            new Date(data.Created),
                                            "MM DD YYYY"
                                          ).format("MMMM DD, YYYY")}{" "}
                                          {data.ExternalUrlSite ? " | " : ""}{" "}
                                          {data.ExternalUrlSite}
                                        </div>
                                      </div>
                                      <div className="news-heading">
                                        <h1 className="news-title">
                                          <a
                                            className="news-title"
                                            // onClick={() =>
                                            //   handledClicked(
                                            //     "External Link -",
                                            //     data.ExternalUrl,
                                            //     data
                                            //   )
                                            // }
                                            href={data?.ExternalUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {data.ShortDescription}
                                          </a>
                                        </h1>
                                      </div>
                                      <div className="news-desc">
                                        <p>
                                          {getDescription(
                                            data.LongDescription,
                                            i
                                          )}

                                          {/*' ('}<a href={data.ExternalUrl} target="_blank">{data.ExternalUrlSite}</a>{')'*/}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <div className="listview-themes">
                                  <div
                                    onClick={() => {
                                      // linkClicked(
                                      //   get(data, "ShortDescription", ""),
                                      //   `/LTWeekly/${moment(
                                      //     data.ItemName,
                                      //     "MM DD YYYY"
                                      //   ).format("MMMM-DD-YYYY")}/${
                                      //     data?.ItemId
                                      //   }`,
                                      //   ""
                                      // )

                                      // window.open(
                                      //   `${
                                      //     process.env.REACT_APP_SITE_URL +
                                      //     "/LTWeekly/" +
                                      //     moment(
                                      //       new Date(data.ItemName)
                                      //     ).format("MMMM-DD-yyyy") +
                                      //     "/" +
                                      //     data.LtweeklyId
                                      //   }`,
                                      //   "_blank"
                                      // );
                                      // redirectToLink(
                                      //   getHrefUrl(data.ShortDescription)
                                      // );
                                      window.open(
                                        `/LTWeekly/${moment(
                                          new Date(data.ItemName)
                                        ).format("MMMM-DD-yyyy")}/${
                                          data.LtweeklyId
                                        }`,
                                        "_blank"
                                      );
                                    }}
                                    className="news-thumb"
                                  >
                                    <img
                                      style={{
                                        backgroundSize: `cover`,
                                        backgroundPosition: `center`,
                                      }}
                                      className={`image${i}`}
                                      src={
                                        !data.PhotoUrl
                                          ? ""
                                          : data.PhotoUrl?.includes(
                                              "westus2storpublic"
                                            )
                                          ? data.PhotoUrl
                                          : process.env.REACT_APP_API_HOSTNAME +
                                            data.PhotoUrl
                                      }
                                      alt="asa"
                                    />
                                  </div>

                                  <div className="news--block mb-0">
                                    {(data.Category ||
                                      data.LTWeeklyThemeCategoryTitles ||
                                      data.SecondaryCategory) && (
                                      <p className="d-flex justify-content-start align-items-center flex-wrap mb-0">
                                        <div className="me-2 marg-btm">
                                          {data.Category && data.Category !== ""
                                            ? data.Category.split("|").map(
                                                (c, index) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      {index > 0 ? " | " : ""}
                                                      <span
                                                        onClick={() =>
                                                          changeCategory(c,index,"news")
                                                      
                                                        }
                                                      >
                                                        {data.LTWeeklyThemeCategoryTitles
                                                          ? c.replaceAll(
                                                              "|",
                                                              " | "
                                                            )
                                                          : c}
                                                      </span>
                                                    </React.Fragment>
                                                  );
                                                }
                                              )
                                            : ""}
                                          {(data.LTWeeklyThemeCategoryTitles ||
                                            data.SecondaryCategory) &&
                                          data.Category !== ""
                                            ? " | "
                                            : " "}

                                          {/* <a onClick={() => changeCategory(data.LTWeeklyThemeCategoryTitles)}>
                                    {data.LTWeeklyThemeCategoryTitles &&
                                      ` | ${data.LTWeeklyThemeCategoryTitles}`}</a>{" "} */}
                                          {data.LTWeeklyThemeCategoryTitles &&
                                            data?.LTWeeklyThemeCategoryTitles?.split(
                                              "|"
                                            ).map((category, index) => (
                                              <React.Fragment key={index}>
                                                {index > 0 && " | "}
                                                <span
                                                  onClick={() =>
                                                    changeCategory(category,index,"news")
                                                  }
                                                >
                                                  {category?.replaceAll(
                                                    "|",
                                                    " | "
                                                  )}
                                                </span>
                                              </React.Fragment>
                                            ))}

                                          {data.SecondaryCategory &&
                                            data?.SecondaryCategory?.split(
                                              " | "
                                            ).map((category, index) => (
                                              <React.Fragment key={index}>
                                                {index > 0 && " | "}
                                                <span
                                                  onClick={() =>
                                                    changeCategory(category,index,"news")
                                                  }
                                                >
                                                  {category?.replaceAll(
                                                    "|",
                                                    " | "
                                                  )}
                                                </span>
                                              </React.Fragment>
                                            ))}
                                        </div>

                                        <div className="marg-btm">
                                          <span className="theme-date">
                                            Theme {data?.ThemeNumber} of LT
                                            Weekly:{" "}
                                            {moment(
                                              data.ItemName,
                                              "MM-DD-YYYY"
                                            ).format("DD MMM, YYYY")}{" "}
                                          </span>
                                        </div>
                                      </p>
                                    )}
                                    {!data.Category &&
                                      !data.LTWeeklyThemeCategoryTitles && (
                                        <p className="news--para d-flex align-items-center justify-content-start">
                                          <span className="theme-date">
                                            Theme {data?.ThemeNumber} of LT
                                            Weekly:{" "}
                                            {moment(
                                              data.ItemName,
                                              "MM-DD-YYYY"
                                            ).format("DD MMM, YYYY")}{" "}
                                          </span>
                                        </p>
                                      )}
                                    <div
                                      className="news-heading"
                                        onClick={() =>{
                                      //     // linkClicked(
                                      //     //   get(data, "ShortDescription", ""),
                                      //     //   `/LTWeekly/${moment(
                                      //     //     data.ItemName,
                                      //     //     "MM DD YYYY"
                                      //     //   ).format("MMMM-DD-YYYY")}/${
                                      //     //     data?.ItemId
                                      //     //   }`,
                                      //     //   ""
                                      //     // )
                                      if(!localStorage.getItem("t")){
                                        openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                          new Date(data.ItemName)
                                        ).format("MMMM-DD-yyyy")}/${
                                          data.LtweeklyId
                                        }`)
                                      }else{
                                          redirectToLink(getHrefUrl(data.ShortDescription))
                                        }
                                      }}
                                    >
                                      <a
                                        //  href={'#'}
                                        // href={`${
                                        //   process.env.REACT_APP_SITE_URL +
                                        //   "/LTWeekly/" +
                                        //   moment(
                                        //     new Date(data.ItemName)
                                        //   ).format("MMMM-DD-yyyy") +
                                        //   "/" +
                                        //   data.LtweeklyId
                                        // }`}
                                        // href={`/LTWeekly/${moment(
                                        //   new Date(data.ItemName)
                                        // ).format("MMMM-DD-yyyy")}/${
                                        //   data.LtweeklyId
                                        // }`}
                                        className="news-title georgia-font text-decoration-underline"
                                        // target="_blank"
                                        // rel="noopener noreferrer"
                                      >
                                        {/* {getThemeTitle(data.ShortDescription)} */}
                                        {data.ShortDescription?.includes(
                                          "<a"
                                        ) ? (
                                          getThemeTitle(data.ShortDescription)
                                        ) : (
                                          <>
                                            {data.ShortDescription.replaceAll(
                                              "<span>",
                                              ""
                                            ).replaceAll("</span>", "")}
                                          </>
                                        )}
                                      </a>
                                    </div>
                                    <div className="newslist-description pb-0 mb-0 default-cursor">
                                      {data.LongDescription && (
                                        <Interweave
                                          content={truncateAndAddReadMore(
                                            data.LongDescription,
                                            160,
                                            data.ShortDescription
                                          )}
                                          transform={(node, children) =>
                                            transformText(
                                              get(data, "ShortDescription", ""),
                                              node,
                                              children
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                    </div>
                  )}

                  <div className="news-load-more-footer mt-3">
                    {!isLoading && totalCount > otherNewsList.length && (
                      <button
                        onClick={() => loadMore()}
                        className="btn btn-lg btn__purple"
                      >
                        {isloadMore ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "load more"
                        )}
                      </button>
                    )}
                    {!isLoading &&
                      otherNewsList.length === 0 &&
                      (totalCount === 0 || totalCount === null) && (
                        <div className="no-data-found py-5">No Data</div>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Drawer
        className="drawer__default filter-by-topic-drower"
        closable="false"
        placement="left"
        onClose={onClose}
        open={isFilterOpen}
      >
        <div className="drawer__default--header">
          <button
            onClick={() => onClose()}
            className="btn drawer__default--header--close"
          >
            <Close />
          </button>
        </div>

        {/* <div className="news-topics-wrapper">
          <ul className="news-topics-list">
            {categoryCount.length > 0 &&
              categoryCount
                .sort((a, b) => a.Category.localeCompare(b.Category))
                .map((data, i) => {
                  if (data.Count) {
                    return (
                      <li key={i}>
                        <label className="custom__checkbox">
                          {data.Category}
                          <input
                            id={i}
                            onChange={() => changeCategory(i)}
                            checked={categories.includes(data.Category)}
                            type="checkbox"
                            className="custom__checkbox--input"
                          />
                          <span className="custom__checkbox--check"></span>
                        </label>
                      </li>
                    );
                  }
                })}
          </ul>
        </div> */}
                  <div className="news-topics-wrapper">
                  {tagsCount.map((header, index) => {
        const allSubCategories = header.Categories.map(cat => cat.Category);
        const isHeaderChecked = allSubCategories.every(cat => selectedCategories.includes(cat));
      // const isActive = expandedCategories.includes(header.HeaderCategory) || header.Categories?.findIndex((c)=>categories.includes(c.Category)) !== -1;
      const isClicked = isCategoryClicked[index];
      // const isActive = expandedCategories.includes(header.HeaderCategory);
        return (
          <div key={index} className="news-topic">
            <div 

            // className="news-topics-dropdown"
            className={`news-topics-dropdown ${isActive(header) && isClicked ? 'active' : ''}`}
             onClick={(e) => {
              e.stopPropagation()
              toggleCategory(header.HeaderCategory,index)}}>
              <label className="custom__checkbox" 
              // onClick={(e) => e.stopPropagation()}
              >
                {header.HeaderCategory}
                <input
                  id={`header-${index}`}
                  onChange={(e) => {
                    e.stopPropagation()
                    // toggleCategory(header.HeaderCategory,index)
                    handleHeaderCheckboxChange(header,index);
                  }}
                  checked={isHeaderChecked}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
              </label>
              <img
                // className={`down-arrow-icon ${expandedCategories.includes(header.HeaderCategory) ? 'active' : ''}`}
                className={`down-arrow-icon ${isActive(header) && isClicked ? 'active' : ''}`}
                src={downArrow}
                alt=""
              />
            </div>
           
            {(isActive(header) && isClicked)  && (
              // 
              <ul className="news-topics-list">
                {header.Categories.length > 0 &&
                  header.Categories
                    .sort((a, b) => a?.Category?.localeCompare(b?.Category))
                    .map((data, i) => {
                        return (
                          <li key={i}>
                            <label className="custom__checkbox">
                              {data.Category}
                              <input
                                id={`sub-${index}-${i}`}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  changeCategory(data.Category, index,"list")}}
                                checked={categories.includes(data.Category)}
                                // checked={selectedCategories.includes(data.Category)}
                                type="checkbox"
                                className="custom__checkbox--input"
                              />
                              <span className="custom__checkbox--check"></span>
                            </label>
                          </li>
                        );
                    })}
              </ul>
            )}
          </div>
        );
      })}
    </div>
      </Drawer>
      <ReactModal
                                      isOpen={mailModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeMailModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                        portalClassName="react-modal- email-media-modal"
                                        style={{
                                             overlay: {
                                            backgroundColor: "rgba(0, 0, 0, 0.75)"
                                                   }
                                        }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                          <h5 className="header-title">Access Theme</h5>
                                          {/* <button
                                            onClick={closeMailModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                    

                                        <div className="bluebg">
                                          <label className="custom-label">Email Address</label>
                                          <input type="text" className="custom-input"
                                          onChange={(e) =>
                                            handleChangeEmail(e.target.value)
                                          } />
                                        </div>
                                        <div style={{ textAlign: "left", color: "red", paddingLeft:"20px"}}>
                                        {errorMessage && (
                                          <>
                                         {errorMessage}
                                         </>
                                        )}
                                        </div>
                                        <div className="text-end">
                                        <button className="bluebtn"
                                        onClick={()=>checkUser(userEmail,themeLink)}>
                                            {emailloader ? (
                                        <Spin indicator={antIcon} />
                                         ) : (
                                         "Request Login Link"
                                          )}
                                         
                                          </button>
                                        </div>
                                        <div className="text-center">
                                          <p className="gated-popoup"><a href="https://lionrb1dev.wpengine.com/iimform?inboundpath=themeweb">Click here</a> if you are not on the list of subscribers.</p>
                                        </div>
                                      </div>
                                    </ReactModal>
                                    <ReactModal
                                      isOpen={successModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeSuccessModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex:"999"
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Check your email</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                      

                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">We sent an email to you at {userEmail}. It has a magic link that will sign you in.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeSuccessModal()}>Close</button>
                                        </div>

                                      </div>
                                    </ReactModal>
                                    <ReactModal
                                      isOpen={isAcknwoledgmentModalOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeAcknowledgmentModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={false}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0 tesssssssssssss"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex:"999"
                                        }
                                      }} 
                                    >
                                      <div class="flex-wrap w-100">
                                      <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Hi There!</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">A LionTree member will review your request and respond within 24-48 hours.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeAcknowledgmentModal()}>OK</button>
                                        </div>
                                      </div>
                                    </ReactModal>
      {/* <Drawer
        className="drawer__default drawer-weekly"
        // closable="false"
        placement="left"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        <div className="drawer__default--header">
          <h4>Filter By Weekly</h4>
          <button
            onClick={() => closeDrawer()}
            className="btn drawer__default--header--close"
          >
            <Close />
          </button>
        </div>

        <div className="scrollable-area">
          <div className="news-topics-wrapper">
            <input
              placeholder="Search"
              className="form-control search-input"
              value={searchDate}
              type="text"
              name="searchText"
              onChange={(e) => {
                handleSearchDateText(e);
              }}
            />
          </div>

          <div className="news-topics-wrapper">
            <ul className="news-topics-list">
              {isLoading ? (
                <>
                  <h1 style={{ marginTop: 200 }}> </h1>
                  <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                </>
              ) : (
                filteredDates.length > 0 &&
                filteredDates.map((data, i) => {
                  if (data.Date) {
                    return (
                      <li key={i}>
                        <label className="custom__checkbox">
                          {data.Date}
                          <input
                            id={i}
                            onChange={() => handlechangeDates(i, data.Date)}
                            // checked={allLTWeeklyDates.includes(data.Date)}
                            checked={selectedDates.includes(data.Date)}
                            type="checkbox"
                            className="custom__checkbox--input"
                          />
                          <span className="custom__checkbox--check"></span>
                        </label>
                      </li>
                    );
                  }
                })
              )}
            </ul>
          </div>
        </div>
      </Drawer> */}
    </>
  );
}
